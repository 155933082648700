const labelStyles = {
  baseStyle: {
    color: 'gray.600',
    fontSize: 'md',
    fontWeight: 'bold',
    lineHeight: '1.25rem',
    letterSpacing: '0.02rem',
  },
}

export default labelStyles
