import { default as chakraTheme } from '@chakra-ui/theme'

/**
 * Typography documentation:
 * https://chakra-ui.com/docs/styled-system/theme#typography
 */

export const fonts = {
  ...chakraTheme.fonts,

  body: `'Lato', sans-serif`,
  heading: `'Lato', sans-serif`,
  'heading-serif': `'Parry Pro', serif`,
} as const

/**
 * There's various ways of referencing these font sizes.
 *
 * You can use `fontSize` on a container component, like `Box`,
 * which will set the font size for everything inside the container:
 *
 * <Box fontSize="md">
 *   <Text>Hello, world!</Text>
 * </Box>
 *
 * You can use `fontSize` on the `Text` component itself:
 *
 * <Text fontSize="sm">
 *   Hello, world!
 * </Text>
 *
 * Chakra UI components also have the added benefit of being able to set a `size` property
 * that can be defined in each component's theme file.
 * This allows us to decouple sizing properties like `fontSize` and `lineHeight`
 * from text styles like `ellipsis`, `title-case`, etc.
 * For the `Text` component, we defined a 1-to-1 relationship between `size` and `fontSize`:
 *
 * <Text size="md" textStyle="ellipsis">
 *   Hello, world!
 * </Text>
 *
 * This is the recommended way of setting the font size for text,
 * but setting it at the container level is also acceptable.
 */
export const fontSizes = {
  ...chakraTheme.fontSizes,

  '3xs': '0.45rem', // 7.2px
  '2xs': '0.625rem', // 10px
  xs: '0.75rem', // 12px
  sm: '0.875rem', // 14px
  md: '1rem', // 16px
  lg: '1.125rem', // 18px
  xl: '1.25rem', // 20px
  '2xl': '1.5rem', // 24px
  '3xl': '1.875rem', // 30px
  '4xl': '2.25rem', // 36px
  '5xl': '3rem', // 48px
  '6xl': '3.75rem', // 60px
  '7xl': '4.5rem', // 72px
  '8xl': '6rem', // 96px
  '9xl': '8rem', // 128px

  // #region Orium theme
  /**
   * !!! DO NOT USE THESE VALUES !!!
   *
   * TODO: Convert usages of these values to their default theme counterparts, or a reasonable alternative.
   * Ex. /src/modules/app/components/GlobalSearch/Search.tsx#82
   * 'xxxl' to '3xl'
   */
  xxs: '0.625rem', // 10px
  base: '1rem', // 16px
  xxl: '1.75rem', // 28px
  xxxl: '2.25rem', // 36px
  xxxxl: '2.625rem', // 42px
  xxxxxl: '3rem', // 48px
  // #endregion
} as const

export const fontWeights = {
  ...chakraTheme.fontWeights,

  hairline: 100,
  thin: 200,
  light: 300,
  normal: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
  extrabold: 800,
  black: 900,
} as const

export const lineHeights = {
  ...chakraTheme.lineHeights,

  normal: 1.375,
  none: 1,
  shorter: 1.25,
  short: 1.375,
  base: 1.5,
  tall: 1.625,
  taller: 2,
  '3': '.75rem',
  '4': '1rem',
  '5': '1.25rem',
  '6': '1.5rem',
  '7': '1.75rem',
  '8': '2rem',
  '9': '2.25rem',
  '10': '2.5rem',
} as const

export const letterSpacings = {
  ...chakraTheme.letterSpacings,

  tighter: '-0.05em',
  tight: '-0.025em',
  normal: 0,
  wide: '0.025em',
  wider: '0.05em',
  widest: '0.1em',
} as const

// Need to match with chakra-ui Theme Key
export const typography = {
  fonts,
  fontSizes,
  fontWeights,
  letterSpacings,
  lineHeights,
} as const

export default typography
