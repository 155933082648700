export const componentEpConnectorFragment = /* GraphQL */ `
  fragment componentEpConnector on ComponentEpConnector {
    sys {
      id
    }
    title(locale: $locale)
    ctaHref(locale: $locale)
    ctaLabel(locale: $locale)
    products
    containerSize(locale: $locale)
    containerMarginTop(locale: $locale)
    containerMarginBottom(locale: $locale)
  }
`
