import { generateVariantAttributeDictionary } from '@modules/cart/components/CartItemList/hooks/useLineItemAttributes'
import { buildHashTable } from '@modules/cart/utils'
import { CartFragment, LineItem, RawProductAttribute } from '../../types'
import { Discount } from '../../utils/checkout/helpers/types'
import { ExtendedGaItemParams } from '../gaTracking'

type gaItemRequiredFields = { item_id: string; item_name: string }
export type gaItemFields = Partial<ExtendedGaItemParams> & gaItemRequiredFields

export const formatPrice = (price: number = 0) => {
  return price / 100
}

export const filterGaItems = (items: ExtendedGaItemParams[]) =>
  items.map((obj) => {
    const filteredObj: gaItemFields = Object.fromEntries(
      Object.entries(obj).filter(([_, value]) => {
        return value !== ''
      })
    ) as gaItemFields
    return filteredObj
  })

/**
 * Returns locale from the RawProductAttribute array
 * @param attributesRaw
 */
export const getLocaleFromRawProductAttribute = (
  attributesRaw: RawProductAttribute[]
) =>
  attributesRaw
    ? attributesRaw.reduce((acc, attribute) => {
        const { attributeDefinition, value } = attribute
        const { type } = attributeDefinition ?? {}
        const { name: attributeType } = type ?? {}
        if (attributeType === 'lenum' || attributeType === 'ltext') {
          return Object.keys(value)?.[0] || acc
        }
        return acc
      }, 'en-US')
    : 'en-US'

/**
 * Returns {discount, coupon} object with discount, applied to the lineItem
 * @param lineItem
 */
const getItemTotal = (lineItem: LineItem) => {
  return lineItem?.discountedPricePerQuantity?.reduce(
    (acc, discount) => {
      return {
        coupon: discount.discountedPrice.includedDiscounts.reduce(
          (prev, discountCode) => (prev += discountCode?.discount?.name + ' '),
          ''
        ),
        discount: discount.discountedPrice.includedDiscounts.reduce(
          (prev, discountCode) =>
            (prev += discountCode?.discountedAmount?.centAmount),
          0
        ),
      }
    },
    {
      discount: 0,
      coupon: '',
    }
  )
}

/**
 * get the items that have values from the cart
 * @param lineItems
 */

export const getItemsWithValues = (
  lineItems?: Partial<CartFragment['lineItems']>
): gaItemFields[] => {
  const items: ExtendedGaItemParams[] =
    lineItems?.map((lineItem, index) => {
      const customFields = buildHashTable(lineItem?.custom?.customFieldsRaw)
      const locale = getLocaleFromRawProductAttribute(
        lineItem?.variant?.attributesRaw as RawProductAttribute[]
      )
      const attributeFields = generateVariantAttributeDictionary(
        lineItem?.variant?.attributesRaw as RawProductAttribute[],
        locale
      )

      const { coupon, discount } = getItemTotal(lineItem as LineItem)

      const price = discount
        ? lineItem?.price?.value?.centAmount - discount
        : lineItem?.price?.value?.centAmount

      return {
        currency: lineItem?.totalPrice?.currencyCode ?? '',
        design_id: customFields?.designID ?? '',
        item_brand: customFields?.artistName ?? '',
        item_category: lineItem?.productType?.name ?? '',
        item_ct_id: lineItem?.productId ?? '',
        item_ct_sku: lineItem?.variant?.sku ?? '',
        item_id: customFields?.childSku ?? '',
        item_name: attributeFields?.class?.value ?? '',
        item_parent_id: customFields?.parentSku ?? '',
        price: formatPrice(price ?? 0),
        quantity: lineItem?.quantity ?? 0,
        item_variant: attributeFields?.['size-display']?.value,
        discount: formatPrice(discount),
        coupon,
        index,
      }
    }) ?? []

  return filterGaItems(items)
}

export const concatDiscountCodesWithSpace = (discountCodes: Discount[]) => {
  const discountCodeNames = Array.from(
    new Set(discountCodes.map((discount) => discount.name))
  )
  return discountCodeNames.join(', ')
}
