export const componentBannerFullFragment = /* GraphQL */ `
  fragment componentBannerFull on ComponentBannerFull {
    sys {
      id
    }
    imageDesktop(locale: $locale) {
      ...contentfulAsset
    }
    imageMobile(locale: $locale) {
      ...contentfulAsset
    }
    eyebrow(locale: $locale)
    title(locale: $locale)
    content(locale: $locale) {
      json
    }
    containerSize(locale: $locale)
    containerMarginTop(locale: $locale)
    containerMarginBottom(locale: $locale)
    ctaAlphaLabel(locale: $locale)
    ctaAlphaHref(locale: $locale)
    ctaBetaLabel(locale: $locale)
    ctaBetaHref(locale: $locale)
    linkLabel1(locale: $locale)
    linkHref1(locale: $locale)
    linkLabel2(locale: $locale)
    linkHref2(locale: $locale)
    linkLabel3(locale: $locale)
    linkHref3(locale: $locale)
    linkLabel4(locale: $locale)
    linkHref4(locale: $locale)
    linkLabel5(locale: $locale)
    linkHref5(locale: $locale)
    linkLabel6(locale: $locale)
    linkHref6(locale: $locale)
    textPosition(locale: $locale)
    theme(locale: $locale)
  }
`
