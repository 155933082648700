import { Box, Link } from '@chakra-ui/react'
import { SPOONFLOWER_BASE_URL } from '@modules/app/constants'
import Image from 'next/image'

export const SpoonFlowerLogo = () => {
  return (
    <Link
      href={SPOONFLOWER_BASE_URL}
      width={{ base: '8.438rem', md: '12.25rem' }}
      marginLeft={{ base: 4, sm: 6, md: 8, lg: 8, xl: 10, '2xl': 12 }}
      marginRight={{ base: 3, sm: 4, md: 6, lg: 6, xl: 8, '2xl': 8 }}
    >
      <Box
        height={{ base: '2rem', md: '3rem' }}
        width="100%"
        position="relative"
        mr={8}
        mb={2}
      >
        <Image
          src="https://cdn.spoonflower.com/baerlauch/production/images/logo.svg"
          alt="spoonflower-logo"
          layout="fill"
        />
      </Box>
    </Link>
  )
}
