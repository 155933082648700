import {
  GetSiteConfigService,
  GetSiteConfigServiceParams,
  SiteConfig,
} from '@oriuminc/base'
import {
  contentfulGraphqlClient,
  getSiteConfigQuery,
  GetSiteConfigQuery,
  GetSiteConfigQueryVariables,
} from '../../utils'

export const contentfulSiteConfigFetchService: GetSiteConfigService = async (
  params
) => {
  const siteConfig = await siteConfigQuery(params)
  if (!siteConfig) return null
  return {
    name: siteConfig.name,
    order: siteConfig.order,
    key: siteConfig.key,
    url: siteConfig.url,
    displayMultiBrandBanner: siteConfig.displayMultiBrandBanner,
    brandLogo: siteConfig.brandLogo,
    brandLogoSmall: siteConfig.brandLogoSmall,
    shortcutIcon: siteConfig.shortcutIcon,
    siblingSites: siteConfig.siblingSitesCollection?.items,
  } as SiteConfig
}

export const siteConfigQuery = async (params?: GetSiteConfigServiceParams) => {
  const query = await contentfulGraphqlClient
    .query<GetSiteConfigQuery, GetSiteConfigQueryVariables>(
      getSiteConfigQuery,
      { locale: params?.locale, key: params?.key }
    )
    .toPromise()

  return query.data?.siteConfigCollection?.items[0]
}
