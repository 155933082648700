import {
  addressFragment,
  baseMoneyFragment,
  cartFragment,
  cartLineItemFragment,
  customerFragment,
  moneyFragment,
  taxedItemPriceFragment,
  taxedPriceFragment,
} from '../fragments'

export const updateMyCartMutation = /* GraphQL */ `
  mutation updateMyCart(
    $id: String!
    $version: Long!
    $actions: [MyCartUpdateAction!]!
    $locale: Locale!
  ) {
    updateMyCart(id: $id, version: $version, actions: $actions) {
      ...cart
    }
  }
  ${cartFragment}
  ${customerFragment}
  ${cartLineItemFragment}
  ${moneyFragment}
  ${taxedPriceFragment}
  ${addressFragment}
  ${baseMoneyFragment}
  ${taxedItemPriceFragment}
`
