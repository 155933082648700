import { AppProps } from 'next/app'
import { Composable } from '@modules/oriuminc/base'
import { CommercetoolsProvider } from '@modules/commercetools'
import { theme } from '@modules/chakra'
import { carouselExtendedTheme } from '@spoonflower/carousel'
import {
  Layout,
  intlConfig,
  COMMERCETOOLS_CLIENT_ID,
  COMMERCETOOLS_CLIENT_SECRET,
  COMMERCETOOLS_HOST,
  COMMERCETOOLS_PROJECT_KEY,
  GOOGLE_TAG_MANAGER_ID,
  BRAND_NAME,
  GOOGLE_ANALYTICS_ID,
  ErrorBoundary,
} from '@modules/app'
import { StrictMode } from 'react'
import { cmsProvider } from '@modules/cms'

const extendedTheme = {
  ...theme,
  components: {
    ...theme.components,
    Progress: carouselExtendedTheme.components.Progress,
    Carousel: carouselExtendedTheme.components.Carousel,
  },
}

const App = ({ Component, pageProps }: AppProps) => {
  const { getSiteConfig } = cmsProvider
  return (
    <ErrorBoundary>
      <Composable
        pageProps={pageProps}
        intl={intlConfig}
        theme={extendedTheme}
        getSiteConfig={getSiteConfig}
        appKey={BRAND_NAME}
      >
        <CommercetoolsProvider
          clientId={COMMERCETOOLS_CLIENT_ID}
          clientSecret={COMMERCETOOLS_CLIENT_SECRET}
          host={COMMERCETOOLS_HOST}
          projectKey={COMMERCETOOLS_PROJECT_KEY}
        >
          <Layout>
            <Component {...pageProps} />
          </Layout>
        </CommercetoolsProvider>
      </Composable>
    </ErrorBoundary>
  )
}

export default App
