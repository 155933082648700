import { useRouter } from 'next/router'
import { useQuery } from '@tanstack/react-query'
import { useComposable } from '@modules/oriuminc/base'
import { productsFetchService } from '../../services'
import { useCommercetools } from '../../components'
import { useUser } from '../useUser'

export const getUseProductBySlugKey = (params: {
  slug?: string
  locale?: string
  customerGroup?: string
}) => [
  'useProductBySlug',
  params.slug ?? null,
  params.locale ?? null,
  params.customerGroup ?? null,
]

const getFilterForSlugByLocale = (slug: string, locale: string) => {
  return [{ model: { value: { path: `slug.${locale}`, values: [slug] } } }]
}

export const useProductBySlug = (slug?: string) => {
  const router = useRouter()
  const { locale, currency } = useComposable()
  const { customer } = useUser()

  const customerGroupId = customer?.customerGroup?.id

  const { graphqlClient, accessToken } = useCommercetools()
  const _slug = slug ?? router.query?.slug?.toString()

  if (!_slug)
    throw new Error(
      '[slug] must be provided by useProductBySlug either by query param or function parameter'
    )

  const queryFilters = getFilterForSlugByLocale(_slug, locale)

  const results = useQuery(
    getUseProductBySlugKey({
      slug: _slug,
      locale,
      customerGroup: customerGroupId,
    }),
    () => {
      return productsFetchService({
        graphqlClient,
        token: accessToken,
        params: {
          queryFilters,
          locale,
          currency,
          customerGroupId,
        },
      })
    },
    {
      refetchOnMount: 'always',
      enabled: Boolean(accessToken),
    }
  )

  const product = results.data?.results[0]

  return {
    ...results,
    product,
  }
}
