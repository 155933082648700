const modalStyles = {
  baseStyle: {
    body: {
      padding: '0',
    },
    dialog: {
      margin: '4',
      paddingX: {
        base: '6',
        md: '12',
      },
      paddingY: {
        base: '8',
        md: '12',
      },
    },
    footer: {
      padding: '0',
    },
    header: {
      padding: '0',
    },
  },
  variants: {
    confirmation: {
      body: {
        padding: '0',
      },
      dialog: {
        marginX: {
          base: '0',
          md: '4',
        },
        marginY: {
          base: '1',
          md: '4',
        },
        paddingX: {
          base: '4',
          md: '12',
        },
        pt: {
          base: '8',
          md: '12',
        },
        pb: {
          base: '8',
          md: '8',
        },
      },
      footer: {
        padding: '0',
      },
      header: {
        padding: '0',
      },
    },
  },
}

export default modalStyles
