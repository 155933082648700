import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { ComponentBannerSplitFragment } from '../../utils'
import { BannerSplit } from '../../../ui'

export const ComponentBannerSplit = ({
  imageDesktop,
  imageMobile,
  eyebrow,
  title,
  content,
  ctaAlphaHref,
  ctaAlphaLabel,
  inverted,
}: ComponentBannerSplitFragment) => {
  return (
    <BannerSplit
      inverted={inverted ?? undefined}
      image={{
        imageDesktop: {
          src: imageDesktop?.url ?? '',
          alt: imageDesktop?.title ?? '',
        },
        imageMobile: {
          src: imageMobile?.url ?? '',
          alt: imageMobile?.title ?? '',
        },
      }}
      text={{
        eyebrow: {
          children: eyebrow,
        },
        title: {
          children: title,
        },
        body: {
          children: content?.json
            ? documentToReactComponents(content.json)
            : undefined,
        },
        ctaButtonPrimary: {
          children: ctaAlphaLabel,
          href: ctaAlphaHref ?? '',
        },
      }}
    />
  )
}
