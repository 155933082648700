import { AxiosInstance } from 'axios'
import { Client } from 'urql'
import { createContext, useCallback, useContext, useRef } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { deleteFromStorage, writeStorage } from '@oriuminc/base'

import {
  LOCAL_STORAGE_CT_ACCESS_TOKEN_ANONYMOUS,
  LOCAL_STORAGE_CT_ACCESS_TOKEN_CUSTOMER,
} from '../../constants'
import { CtAccessTokenInterface } from '../../types'
import { axiosCreateClient, urqlCreateClient } from '../../utils'
import { useAnonymousToken, useAnonymousTokenKey } from '../../hooks'
import { useUserAuthHandler } from '../../hooks/useUserAuthHandler'
import { Analytics } from '../Analytics'

export interface CommercetoolsContextInterface {
  graphqlClient: Client
  httpClient: AxiosInstance
  projectKey: string
  host: string
  clientId: string
  clientSecret: string
  accessToken: string
  anonymousToken: string
  customerToken: string
  setAnonymousToken: (token: CtAccessTokenInterface | null) => void
  setCustomerToken: (token: CtAccessTokenInterface | null) => void
}

const CommercetoolsContext = createContext<
  CommercetoolsContextInterface | undefined
>(undefined)

export interface CommercetoolsContext
  extends Partial<
    Pick<
      CommercetoolsContextInterface,
      'host' | 'projectKey' | 'clientId' | 'clientSecret'
    >
  > {
  children: JSX.Element
}

export const CommercetoolsProvider = ({
  children,
  host = '',
  projectKey = '',
  clientId = '',
  clientSecret = '',
}: CommercetoolsContext) => {
  const queryClient = useQueryClient()
  const anonymousToken = useRef('')
  const customerToken = useRef('')

  // Configure setAnonymousToken handler
  const setAnonymousTokenHandler = useCallback(
    (token: CtAccessTokenInterface | null) => {
      if (!token) {
        anonymousToken.current = ''
        deleteFromStorage(LOCAL_STORAGE_CT_ACCESS_TOKEN_ANONYMOUS)
        queryClient.invalidateQueries([useAnonymousTokenKey])
        return
      }

      anonymousToken.current = token.access_token
      writeStorage<CtAccessTokenInterface>(
        LOCAL_STORAGE_CT_ACCESS_TOKEN_ANONYMOUS,
        token
      )
    },
    [queryClient]
  )

  // Configure setAccessTokenCustomer handler
  const setCustomerTokenHandler = useCallback(
    (token: CtAccessTokenInterface | null) => {
      if (!token) {
        customerToken.current = ''
        deleteFromStorage(LOCAL_STORAGE_CT_ACCESS_TOKEN_CUSTOMER)
        return
      }

      customerToken.current = token.access_token
      writeStorage<CtAccessTokenInterface>(
        LOCAL_STORAGE_CT_ACCESS_TOKEN_CUSTOMER,
        token
      )
    },
    []
  )

  // Configure axios client
  const httpClient = useRef(axiosCreateClient({ host, projectKey }))

  // Configure urql client
  const graphqlClient = useRef(
    urqlCreateClient({
      host,
      projectKey,
      config: { requestPolicy: 'network-only' },
    })
  )

  // Context values
  const commercetoolsContext = {
    httpClient: httpClient.current,
    graphqlClient: graphqlClient.current,
    host,
    projectKey,
    clientId,
    clientSecret,
    accessToken: customerToken.current || anonymousToken.current,
    anonymousToken: anonymousToken.current,
    customerToken: customerToken.current,
    setAnonymousToken: setAnonymousTokenHandler,
    setCustomerToken: setCustomerTokenHandler,
  }

  // Handle user auth
  useUserAuthHandler({ commercetoolsContext })

  // Handle anonymous token
  useAnonymousToken({ commercetoolsContext })

  return (
    <CommercetoolsContext.Provider value={commercetoolsContext}>
      <>
        <Analytics />
        {children}
      </>
    </CommercetoolsContext.Provider>
  )
}

export const useCommercetools = () => {
  const context = useContext(CommercetoolsContext)
  if (context === undefined) {
    throw new Error(
      'useCommercetools must be used within a CommercetoolsProvider'
    )
  }
  return context
}
