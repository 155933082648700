import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { CTAccessToken } from 'pages/api/commercetools/types'

async function getToken() {
  try {
    const { data } = await axios.get('/api/token')
    if (data.success) {
      return data.token as CTAccessToken
    } else {
      return null
    }
  } catch (err) {
    console.log(err)
    return null
  }
}

export const useClientSideCookie = () => {
  const { data: accessToken } = useQuery(['accessToken'], getToken)

  return accessToken
}
