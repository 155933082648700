import { imagesFragment } from './imagesFragment'
import { productPriceFragment } from './moneyFragment'

export const variantFragment = /* GraphQL */ `
  fragment variant on ProductSearchVariant {
    id
    key
    sku
    images {
      ...images
    }
    price(currency: $currency, customerGroupId: $customerGroupId) {
      ...productPrice
    }
    attributesRaw {
      name
      value
    }
    availability {
      noChannel {
        id
        isOnStock
        availableQuantity
      }
      channels {
        results {
          channel {
            key
            name(locale: $locale)
          }
          availability {
            id
            isOnStock
            availableQuantity
          }
        }
      }
    }
  }
  ${imagesFragment}
  ${productPriceFragment}
`
