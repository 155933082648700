import {
  fonts,
  fontSizes,
  fontWeights,
  lineHeights,
  letterSpacings,
} from './foundations/typography'

import { palette } from './foundations/colors'
import { default as space } from './foundations/spacing'

// For key/props ref: https://v0.chakra-ui.com/style-props
/*
TODO_dalin better mapping
ex:
{
  'Body-Default': {base: body_default.mobile, lg: body_default.desktop},
  'Desktop/Body-Default': body_default.mobile,
  'Mobile/Body-Default': body_default.desktop
  etc...
}
*/
export const variants = {
  // #region Orium theme
  // token name needs to be exact match
  blockQuote: {
    fontFamily: fonts.body,
    fontWeight: fontWeights.normal,
    lineHeight: lineHeights.base,
    borderLeft: `${space.xxxs} solid ${palette.primary[900]}`,
    marginLeft: space.sm,
    padding: `0 ${space.sm}`,
    color: palette.shading[600],
    fontSize: fontSizes.lg,
    fontStyle: 'italic',
  },
  body: {
    fontFamily: fonts.body,
    fontSize: fontSizes.md,
    fontWeight: fontWeights.normal,
    lineHeight: lineHeights.short,
  },
  caption: {
    display: 'block',
    fontSize: { base: fontSizes.xs, md: fontSizes.sm },
    lineHeight: { base: lineHeights.shorter, md: lineHeights.normal },
  },
  headline: {
    fontFamily: fonts.heading,
    fontSize: { base: fontSizes.md, lg: fontSizes.lg },
    fontWeight: fontWeights.medium,
    lineHeight: lineHeights.base,
  },
  subhead: {
    fontWeight: fontWeights.medium,
    lineHeight: lineHeights.base,
  },
  tag: {
    height: space.md,
    backgroundColor: palette.warning[700],
    color: palette.shading[100],
    textTransform: 'uppercase',
    letterSpacing: letterSpacings.wider,
    fontWeight: fontWeights.bold,
    textAlign: 'center',
    top: space.sm,
    right: space.sm,
    padding: `${space.xxxs} ${space.xs}`,
    fontSize: fontSizes.xs,
  },
  textHighlight: {
    fontSize: fontSizes.lg,
    lineHeight: lineHeights.base,
    color: palette.primary[900],
    padding: space.md,
  },
  // #endregion

  // #region Spoonflower theme
  /**
   * If you're wondering "Why isn't this working!?", you may need to add "min-width: 0"
   * to the parent of the container you set this textStyle on.
   */
  ellipsis: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  heading: {
    fontFamily: 'heading',
    fontWeight: 'bold',
    letterSpacing: 'normal',
    lineHeight: 'shorter',
  },
  'heading-serif': {
    fontFamily: 'heading-serif',
    fontWeight: 'normal',
    letterSpacing: 'normal',
    lineHeight: 'shorter',
  },
  p: {
    marginBottom: '4',
    marginTop: '4',
  },
  'title-case': {
    textTransform: 'capitalize',
  },
  'required-indicator': {
    color: 'gray.600',
    fontWeight: 'bold',
    lineHeight: 'none',
    verticalAlign: 'baseline',
    fontSize: 'sm',
  },
  // #endregion
}

export const sizes = {
  // #region Orium theme
  // token name needs to be exact match
  // XXL
  'xx-large': {
    fontSize: { base: '2.25rem', lg: '4.5rem' },
    fontWeight: fontWeights.bold,
    lineHeight: '120%',
    textTransform: 'none',
  },
  XXL: {
    fontSize: { base: '2.25rem', lg: '4.5rem' },
    fontWeight: fontWeights.bold,
    lineHeight: '120%',
    textTransform: 'none',
  },
  'Desktop/XXL': {
    fontSize: '4.5rem',
    fontWeight: fontWeights.bold,
    lineHeight: '120%',
    textTransform: 'none',
  },
  'Mobile/XXL': {
    fontSize: '2.25',
    fontWeight: fontWeights.bold,
    lineHeight: '120%',
    textTransform: 'none',
  },
  // XL
  'x-large': {
    fontSize: { base: '2rem', lg: '3.75rem' },
    fontWeight: fontWeights.bold,
    lineHeight: '120%',
    textTransform: 'none',
  },
  XL: {
    fontSize: { base: '2rem', lg: '3.75rem' },
    fontWeight: fontWeights.bold,
    lineHeight: '120%',
    textTransform: 'none',
  },
  'Desktop/XL': {
    fontSize: '3.75rem',
    fontWeight: fontWeights.bold,
    lineHeight: '120%',
    textTransform: 'none',
  },
  'Mobile/XL': {
    fontSize: '2rem',
    fontWeight: fontWeights.bold,
    lineHeight: '120%',
    textTransform: 'none',
  },
  // L
  large: {
    fontSize: { base: '1.5rem', lg: '2.75rem' },
    fontWeight: fontWeights.bold,
    lineHeight: '120%',
    textTransform: 'none',
  },
  L: {
    fontSize: { base: '1.5rem', lg: '2.75rem' },
    fontWeight: fontWeights.bold,
    lineHeight: '120%',
    textTransform: 'none',
  },
  'Desktop/L': {
    fontSize: '2.75rem',
    fontWeight: fontWeights.bold,
    lineHeight: '120%',
    textTransform: 'none',
  },
  'Mobile/L': {
    fontSize: '1.5rem',
    fontWeight: fontWeights.bold,
    lineHeight: '120%',
    textTransform: 'none',
  },
  // M
  medium: {
    fontSize: { base: '1.25rem', lg: '1.75rem' },
    fontWeight: fontWeights.bold,
    lineHeight: '120%',
    textTransform: 'none',
  },
  M: {
    fontSize: { base: '1.25rem', lg: '1.75rem' },
    fontWeight: fontWeights.bold,
    lineHeight: '120%',
    textTransform: 'none',
  },
  'Desktop/M': {
    fontSize: '1.75rem',
    fontWeight: fontWeights.bold,
    lineHeight: '120%',
    textTransform: 'none',
  },
  'Mobile/M': {
    fontSize: '1.25rem',
    fontWeight: fontWeights.bold,
    lineHeight: '120%',
    textTransform: 'none',
  },
  // S
  small: {
    fontSize: { base: '1rem', md: '1.5rem' },
    fontWeight: fontWeights.bold,
    lineHeight: '120%',
    textTransform: 'none',
  },
  S: {
    fontSize: { base: '1rem', md: '1.5rem' },
    fontWeight: fontWeights.bold,
    lineHeight: '120%',
    textTransform: 'none',
  },
  'Desktop/S': {
    fontSize: '1.5rem',
    fontWeight: fontWeights.bold,
    lineHeight: '120%',
    textTransform: 'none',
  },
  'Mobile/S': {
    fontSize: '1rem',
    fontWeight: fontWeights.bold,
    lineHeight: '120%',
    textTransform: 'none',
  },
  // XS
  'x-small': {
    fontSize: { base: '1rem', md: '1.25rem' },
    fontWeight: fontWeights.extrabold,
    lineHeight: '120%',
    textTransform: 'none',
  },
  XS: {
    fontSize: { base: '1rem', md: '1.25rem' },
    fontWeight: fontWeights.extrabold,
    lineHeight: '120%',
    textTransform: 'none',
  },
  'Desktop/XS': {
    fontSize: '1.25rem',
    fontWeight: fontWeights.extrabold,
    lineHeight: '120%',
    textTransform: 'none',
  },
  'Mobile/XS': {
    fontSize: '1rem',
    fontWeight: fontWeights.extrabold,
    lineHeight: '120%',
    textTransform: 'none',
  },
  // Desktop/Body-XL
  'body-x-large': {
    fontSize: { base: '1.25rem', lg: '1.5rem' },
    fontWeight: fontWeights.bold,
    lineHeight: '150%',
    textTransform: 'none',
  },
  'Body-XL': {
    fontSize: { base: '1.25rem', lg: '1.5rem' },
    fontWeight: fontWeights.bold,
    lineHeight: '150%',
    textTransform: 'none',
  },
  'Desktop/Body-XL': {
    fontSize: '1.5rem',
    fontWeight: fontWeights.bold,
    lineHeight: '150%',
    textTransform: 'none',
  },
  'Mobile/Body-XL': {
    fontSize: '1.25rem',
    fontWeight: fontWeights.bold,
    lineHeight: '150%',
    textTransform: 'none',
  },
  // Body-L
  'body-large': {
    fontSize: { base: '1rem', lg: '1.25rem' },
    fontWeight: fontWeights.normal,
    lineHeight: '150%',
    textTransform: 'none',
  },
  'Body-L': {
    fontSize: { base: '1rem', lg: '1.25rem' },
    fontWeight: fontWeights.normal,
    lineHeight: '150%',
    textTransform: 'none',
  },
  'Desktop/Body-L': {
    fontSize: '1.25rem',
    fontWeight: fontWeights.normal,
    lineHeight: '150%',
    textTransform: 'none',
  },
  'Mobile/Body-L': {
    fontSize: '1rem',
    fontWeight: fontWeights.normal,
    lineHeight: '150%',
    textTransform: 'none',
  },
  // Body-Default
  'body-default': {
    fontSize: { base: '0.875rem', md: '1rem' },
    fontWeight: fontWeights.normal,
    lineHeight: '150%',
    textTransform: 'none',
  },
  'Body-Default': {
    fontSize: { base: '0.875rem', md: '1rem' },
    fontWeight: fontWeights.normal,
    lineHeight: '150%',
    textTransform: 'none',
  },
  'Desktop/Body-Default': {
    fontSize: '1rem',
    fontWeight: fontWeights.normal,
    lineHeight: '150%',
    textTransform: 'none',
  },
  'Mobile/Body-Default': {
    fontSize: '0.875rem',
    fontWeight: fontWeights.normal,
    lineHeight: '150%',
    textTransform: 'none',
  },
  // Body-S
  'body-small': {
    fontSize: { base: '0.75rem', md: '0.875rem' },
    fontWeight: fontWeights.normal,
    lineHeight: '150%',
    textTransform: 'none',
  },
  'Body-S': {
    fontSize: { base: '0.75rem', md: '0.875rem' },
    fontWeight: fontWeights.normal,
    lineHeight: '150%',
    textTransform: 'none',
  },
  'Desktop/Body-S': {
    fontSize: '0.875rem',
    fontWeight: fontWeights.normal,
    lineHeight: '150%',
    textTransform: 'none',
  },
  'Mobile/Body-S': {
    fontSize: '0.75rem',
    fontWeight: fontWeights.normal,
    lineHeight: '150%',
    textTransform: 'none',
  },
  // Body-XS
  'body-x-small': {
    fontSize: { base: '0.625rem', md: '0.75rem' },
    fontWeight: fontWeights.normal,
    lineHeight: '125%',
    textTransform: 'none',
  },
  'Body-XS': {
    fontSize: { base: '0.625rem', md: '0.75rem' },
    fontWeight: fontWeights.normal,
    lineHeight: '125%',
    textTransform: 'none',
  },
  'Desktop/Body-XS': {
    fontSize: '0.75rem',
    fontWeight: fontWeights.normal,
    lineHeight: '125%',
    textTransform: 'none',
  },
  'Mobile/Body-XS': {
    fontSize: '0.625rem',
    fontWeight: fontWeights.normal,
    lineHeight: '125%',
    textTransform: 'none',
  },
  Eyebrow: {
    fontSize: { base: fontSizes.xs, md: fontSizes.sm },
    textTransform: 'uppercase',
    letterSpacing: letterSpacings.wider,
    lineHeight: { base: '4', md: '5' },
    fontWeight: fontWeights.extrabold,
    maxWidth: '450px',
  },
  'Desktop/Eyebrow': {
    fontSize: fontSizes.sm,
    textTransform: 'uppercase',
    letterSpacing: letterSpacings.wider,
    lineHeight: '5',
    fontWeight: fontWeights.extrabold,
    maxWidth: '450px',
  },
  'Mobile/Eyebrow': {
    fontSize: fontSizes.xs,
    textTransform: 'uppercase',
    letterSpacing: letterSpacings.wider,
    lineHeight: '4',
    fontWeight: fontWeights.extrabold,
    maxWidth: '450px',
  },
  'Desktop/Link-S': {
    fontSize: fontSizes.sm,
    fontWeight: fontWeights.bold,
    color: 'red',
  },
  // #endregion
}

export type TextStyles = typeof variants & typeof sizes

const textStyles = { ...sizes, ...variants }

export default textStyles
