import { CtServiceDeps, CustomerParams } from '../../types'
import {
  CustomerChangeMyPasswordMutation,
  CustomerChangeMyPasswordMutationVariables,
  CustomerCreatePasswordResetTokenMutation,
  CustomerCreatePasswordResetTokenMutationVariables,
  CustomerLogInMutation,
  CustomerLogInMutationVariables,
  CustomerResetPasswordMutation,
  CustomerResetPasswordMutationVariables,
  CustomerSignMeUpMutation,
  CustomerSignMeUpMutationVariables,
  GetCustomerDataQuery,
  UpdateMyCustomerMutation,
  UpdateMyCustomerMutationVariables,
} from '../../types/commercetools-schema'
import { getAuthHeader } from '../../utils'
import { GraphqlClientRequired } from '../../utils/GraphqlClientRequired'
import { getCustomerDataQuery } from '../../utils/queries'
import {
  customerChangeMyPasswordMutation,
  customerCreatePasswordResetTokenMutation,
  customerLogInMutation,
  customerResetPasswordMutation,
  customerSignMeUpMutation,
  updateMyCustomerMutation,
} from '../../utils/mutations'

export const customerChangeMyPasswordService = async ({
  graphqlClient,
  token,
  params,
}: CtServiceDeps<CustomerChangeMyPasswordMutationVariables>) => {
  if (!graphqlClient) {
    throw new GraphqlClientRequired()
  }

  const res = await graphqlClient
    .mutation<
      CustomerChangeMyPasswordMutation,
      CustomerChangeMyPasswordMutationVariables
    >(customerChangeMyPasswordMutation, params, {
      fetchOptions: {
        headers: {
          authorization: getAuthHeader(token),
        },
      },
    })
    .toPromise()

  if (res.error) {
    throw new Error(res.error.graphQLErrors[0]?.message || res.error.message)
  }

  return res.data?.customerChangeMyPassword
}

export const customerSignUpService = async ({
  graphqlClient,
  params,
  token,
}: CtServiceDeps<{
  variables: CustomerSignMeUpMutationVariables
}>) => {
  if (!graphqlClient) {
    throw new GraphqlClientRequired()
  }

  const res = await graphqlClient
    .mutation<CustomerSignMeUpMutation, CustomerSignMeUpMutationVariables>(
      customerSignMeUpMutation,
      params?.variables,
      {
        fetchOptions: {
          headers: {
            authorization: getAuthHeader(token),
          },
        },
      }
    )
    .toPromise()

  if (res.error) {
    throw new Error(res.error.graphQLErrors[0]?.message || res.error.message)
  }

  return res.data?.customerSignMeUp.customer.id
}

export const customerLogInService = async ({
  graphqlClient,
  params,
  token,
}: CtServiceDeps<{
  variables: CustomerLogInMutationVariables
}>) => {
  if (!graphqlClient) {
    throw new GraphqlClientRequired()
  }

  const res = await graphqlClient
    .mutation<CustomerLogInMutation, CustomerLogInMutationVariables>(
      customerLogInMutation,
      params?.variables,
      {
        fetchOptions: {
          headers: {
            authorization: getAuthHeader(token),
          },
        },
      }
    )
    .toPromise()

  if (res.error) {
    throw new Error(res.error.graphQLErrors[0]?.message || res.error.message)
  }

  return res.data?.customerSignMeIn.customer.id
}

export const customerFetchService = async ({
  graphqlClient,
  token,
  params
}: CtServiceDeps<CustomerParams>) => {
  if (!graphqlClient) {
    throw new GraphqlClientRequired()
  }

  const res = await graphqlClient
    .query<GetCustomerDataQuery>(
      getCustomerDataQuery,
      params, {
      fetchOptions: {
        headers: {
          authorization: getAuthHeader(token),
        },
      },
    })
    .toPromise()

  if (res.error) {
    throw new Error(res.error.graphQLErrors[0]?.message || res.error.message)
  }

  return res.data?.me.customer
}

export const customerPasswordResetTokenService = async ({
  graphqlClient,
  params,
  token,
}: CtServiceDeps<CustomerCreatePasswordResetTokenMutationVariables>) => {
  if (!graphqlClient) {
    throw new GraphqlClientRequired()
  }

  const res = await graphqlClient
    .mutation<
      CustomerCreatePasswordResetTokenMutation,
      CustomerCreatePasswordResetTokenMutationVariables
    >(customerCreatePasswordResetTokenMutation, params, {
      fetchOptions: {
        headers: {
          authorization: getAuthHeader(token),
        },
      },
    })
    .toPromise()

  if (res.error) {
    throw new Error(res.error.graphQLErrors[0]?.message || res.error.message)
  }

  return res.data
}

export const customerResetPasswordService = async ({
  graphqlClient,
  params,
  token,
}: CtServiceDeps<CustomerResetPasswordMutationVariables>) => {
  if (!graphqlClient) {
    throw new GraphqlClientRequired()
  }

  const res = await graphqlClient
    .mutation<
      CustomerResetPasswordMutation,
      CustomerResetPasswordMutationVariables
    >(customerResetPasswordMutation, params, {
      fetchOptions: {
        headers: {
          authorization: getAuthHeader(token),
        },
      },
    })
    .toPromise()

  if (res.error) {
    throw new Error(res.error.graphQLErrors[0]?.message || res.error.message)
  }

  return res.data
}

export const updateMyCustomerService = async ({
  graphqlClient,
  token,
  params,
}: CtServiceDeps<UpdateMyCustomerMutationVariables>) => {
  if (!graphqlClient) {
    throw new GraphqlClientRequired()
  }

  const res = await graphqlClient
    .mutation<UpdateMyCustomerMutation, UpdateMyCustomerMutationVariables>(
      updateMyCustomerMutation,
      params,
      {
        fetchOptions: {
          headers: {
            authorization: getAuthHeader(token),
          },
        },
      }
    )
    .toPromise()

  if (res.error) {
    throw new Error(res.error.graphQLErrors[0]?.message || res.error.message)
  }

  return res.data?.updateMyCustomer
}
