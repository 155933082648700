import { User } from '@modules/spoonflower/utils/graphql/generated/graphql'
import { useComposable } from '@modules/oriuminc/base'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { getCommercetoolsLocaleFromCurrency } from '../useChangeCartCurrency'
import useMergeCarts from '../useMergeCarts'
import { defaults } from '../useUserPreferences'

interface WhoAmIResponse {
  data: { user: User }
}

export const useWhoAmIKey = ['useWhoAmI']

export default function useWhoAmI(options = {}) {
  const { locale, setLocale } = useComposable()
  const { mutateAsync: mergeCarts } = useMergeCarts()

  return useQuery<User | void>(
    useWhoAmIKey,
    async () => {
      const options = {
        withCredentials: true,
      }
      const url = '/api/spoonflower/who-am-i'
      const response = await axios.get<WhoAmIResponse>(url, options)
      const {
        data: {
          data: { user },
        },
      } = response

      const { ctUser, guest, guest_cart_id, preferences } = user
      const currency = preferences?.currency ?? defaults.currency
      const newLocale = getCommercetoolsLocaleFromCurrency(currency)

      if (!guest && guest_cart_id) {
        await mergeCarts({
          guestCartID: guest_cart_id!,
          userCustomerID: ctUser?.id!,
          userCurrency: preferences?.currency!,
        })
      }

      if (locale !== newLocale) {
        setLocale(newLocale)
      }

      return user
    },
    {
      ...options,
    }
  )
}
