export const componentBannerSplitFragment = /* GraphQL */ `
  fragment componentBannerSplit on ComponentBannerSplit {
    sys {
      id
    }
    imageDesktop(locale: $locale) {
      ...contentfulAsset
    }
    imageMobile(locale: $locale) {
      ...contentfulAsset
    }
    eyebrow(locale: $locale)
    title(locale: $locale)
    content(locale: $locale) {
      json
    }
    ctaAlphaLabel(locale: $locale)
    ctaAlphaHref(locale: $locale)
    inverted(locale: $locale)
    containerSize(locale: $locale)
    containerMarginTop(locale: $locale)
    containerMarginBottom(locale: $locale)
  }
`
