import { pushPageViewToDataLayer, UserStats } from '@spoonflower/user-analytics'
import { EVENT_USER_INFORMATION } from '@modules/app'
import { buildHashTable } from '@modules/cart/utils'
import { GaEventsCollection, GaItemParams } from '@oriuminc/base'
import { Address, Order } from '@oriuminc/commercetools'
import { StatsDataLayer } from '../../../app/hooks/useUserStats'
import {
  CartFragment,
  CartLineItemFragment,
  Maybe,
  ProductFragment,
} from '../types/commercetools-schema'
import {
  GaApplyCouponEvent,
  GaCartFragment,
  GaRemoveCouponEvent,
  GaReviewOrderEvent,
  GaUpdateLocationEvent,
} from '../types/gaTracking'
import { getCartTotals } from '../utils'
import { Discount } from '../utils/checkout/helpers/types'
import {
  concatDiscountCodesWithSpace,
  filterGaItems,
  formatPrice,
  getItemsWithValues,
} from './helpers'

/**
 * Find an CartLineItemFragment by id
 * @param cartItem
 * @param id
 */
const findCartItemByProductId = (
  cartItem: CartLineItemFragment,
  id: string
) => {
  return cartItem.productId === id
}

/**
 * Find an CartLineItemFragment by line item id
 * @param cartItem
 * @param id
 */
const findCartItemByLineItemId = (
  cartItem: CartLineItemFragment,
  id: string
) => {
  return cartItem.id === id
}

export interface ExtendedGaItemParams extends GaItemParams {
  design_id?: string
  item_brand?: string
  item_ct_id?: string
  item_ct_sku?: string
  item_parent_id?: string
}

/**
 * Tracks a cartItem update
 */
export const gaTrackUpdateCart = (params: {
  lineItemId: string
  quantity?: number
  prevCart?: Maybe<GaCartFragment>
  nextCart?: GaCartFragment
}) => {
  const { prevCart, lineItemId } = params
  const item = prevCart?.lineItems?.find((el) =>
    findCartItemByLineItemId(el, lineItemId)
  )
  const nextQty = params?.quantity ?? 0
  const prevQty = item?.quantity ?? 0
  const quantity = Math.abs(nextQty - prevQty)

  if (nextQty > prevQty) {
    gaTrackAddToCart({ ...params, productId: item?.productId!, quantity })
  } else {
    gaTrackRemoveFromCart({ ...params, quantity })
  }
}

/**
 * Tracks the add to cart event
 */
export const gaTrackAddToCart = (params: {
  productId: string
  quantity?: number
  prevCart?: Maybe<GaCartFragment>
  nextCart?: GaCartFragment
}) => {
  const { nextCart, quantity, productId } = params
  const item = nextCart?.lineItems?.find((el) =>
    findCartItemByProductId(el, productId)
  )
  const customFields = buildHashTable(item?.custom?.customFieldsRaw)

  if (!item) {
    return
  }

  const itemParams: ExtendedGaItemParams = {
    item_ct_sku: item.productId ?? '',
    item_id: item.variant?.sku ?? '',
    item_parent_id: customFields?.parentSku ?? '',
    item_category: item.productType?.name ?? '',
    item_name: item.name ?? '',
    item_brand: customFields?.artistName ?? '',
    design_id: customFields?.designID ?? '',
    currency: item.price?.value.currencyCode ?? '',
    price: formatPrice(item.price?.value.centAmount),
    quantity,
  }

  const items = filterGaItems([itemParams])

  gaTrackEventPush({
    name: 'add_to_cart',
    params: {
      value: formatPrice(item.totalPrice?.centAmount),
      currency: item.totalPrice?.currencyCode ?? '',
      items,
    },
  })
}

/**
 * Tracks the remove from cart event
 */
export const gaTrackRemoveFromCart = (params: {
  lineItemId: string
  quantity?: number
  prevCart?: Maybe<GaCartFragment>
}) => {
  const { prevCart, quantity, lineItemId } = params
  const item = prevCart?.lineItems?.find((el) =>
    findCartItemByLineItemId(el, lineItemId)
  )
  const customFields = buildHashTable(item?.custom?.customFieldsRaw)

  if (!item) {
    return
  }

  const itemParams: ExtendedGaItemParams = {
    item_ct_sku: item.productId ?? '',
    item_id: item.variant?.sku ?? '',
    item_parent_id: customFields?.parentSku ?? '',
    item_category: item.productType?.name ?? '',
    item_name: item.name ?? '',
    item_brand: customFields?.artistName ?? '',
    design_id: customFields?.designID ?? '',
    currency: item.price?.value.currencyCode ?? '',
    price: formatPrice(item.price?.value.centAmount),
    quantity,
  }

  const items = filterGaItems([itemParams])

  gaTrackEventPush({
    name: 'remove_from_cart',
    params: {
      remove: {
        items,
      },
    },
  })
}

export const gaTrackViewCart = (params: {
  cart?: GaCartFragment
  discounts?: Discount[]
}) => {
  const { cart, discounts } = params
  if (!cart) {
    return
  }

  const items = getItemsWithValues(cart.lineItems)
  const { total, shipping, discount } = getCartTotals(cart as CartFragment)

  gaTrackEventPush({
    name: 'view_cart',
    params: {
      value: formatPrice(total - shipping),
      currency: cart.totalPrice?.currencyCode ?? '',
      cart_id: cart.id,
      step: 0,
      coupon: concatDiscountCodesWithSpace(discounts ?? []),
      discount: formatPrice(discount),
      items,
    },
  })
}

export const gaTrackBeginCheckout = (params: {
  cart?: GaCartFragment
  discounts?: Discount[]
}) => {
  const { cart, discounts } = params
  if (!cart) {
    return
  }

  const items = getItemsWithValues(cart.lineItems)
  const { total, shipping } = getCartTotals(cart)
  const { discount } = getCartTotals(cart as CartFragment)

  gaTrackEventPush({
    name: 'begin_checkout',
    params: {
      value: formatPrice(total - shipping),
      currency: cart.totalPrice?.currencyCode ?? '',
      coupon: concatDiscountCodesWithSpace(discounts ?? []),
      items,
      discount: formatPrice(discount),
    },
  })
}

export const gaTrackAddShippingInfo = (params: {
  cart?: GaCartFragment
  discounts?: Discount[]
  shippingTier?: string
}) => {
  const { cart, discounts, shippingTier } = params
  if (!cart) {
    return
  }

  const items = getItemsWithValues(cart.lineItems)
  const { total, discount, shipping } = getCartTotals(cart)

  gaTrackEventPush({
    name: 'add_shipping_info',
    params: {
      cart_id: cart.id,
      step: 2,
      value: formatPrice(total),
      currency: cart.totalPrice?.currencyCode ?? '',
      coupon: concatDiscountCodesWithSpace(discounts ?? []),
      shipping_tier: shippingTier,
      shipping: formatPrice(shipping),
      discount: formatPrice(discount),
      items,
    },
  })
}

export const gaTrackAddPaymentInfo = (params: {
  cart?: GaCartFragment
  discounts?: Discount[]
  paymentType?: string
  shippingTier?: string
}) => {
  const { cart, discounts, paymentType, shippingTier } = params
  if (!cart) {
    return
  }

  const items = getItemsWithValues(cart.lineItems)
  const { total, discount, shipping, tax } = getCartTotals(cart)

  gaTrackEventPush({
    name: 'add_payment_info',
    params: {
      cart_id: cart.id,
      step: 3,
      value: formatPrice(total),
      currency: cart.totalPrice?.currencyCode ?? '',
      coupon: concatDiscountCodesWithSpace(discounts ?? []),
      payment_type: paymentType ?? '',
      shipping_tier: shippingTier,
      shipping: formatPrice(shipping),
      tax: formatPrice(tax),
      discount: formatPrice(discount),
      items,
    },
  })
}

// copied from gaTrackAddPaymentInfo above
export const gaTrackReviewOrder = (params: {
  cart?: GaCartFragment
  discounts?: Discount[]
  shippingTier?: string
  paymentType?: string
}) => {
  const { cart, discounts, shippingTier } = params // passing in transactionId as optional param for starter kit requirement
  if (!cart) {
    return
  }

  const items = getItemsWithValues(cart.lineItems)
  const { total, discount, shipping, tax } = getCartTotals(cart)

  gaTrackEventPush({
    name: 'review_order',
    params: {
      cart_id: cart.id,
      step: 4,
      currency: cart.totalPrice?.currencyCode ?? '',
      value: formatPrice(total),
      coupon: concatDiscountCodesWithSpace(discounts ?? []),
      payment_type: params.paymentType ?? '',
      shipping_tier: shippingTier,
      shipping: formatPrice(shipping),
      tax: formatPrice(tax),
      discount: formatPrice(discount),
      items,
    },
  })
}

export const gaTrackPurchase = (params: {
  order?: Order | undefined
  discounts?: Discount[]
  transactionId: string
  paymentType?: string
}) => {
  const { order, discounts, transactionId } = params
  if (!order) {
    return
  }
  const items = getItemsWithValues(order.lineItems)
  const { total, discount, tax, shipping } = getCartTotals({
    shippingInfo: order.shippingInfo,
    taxedPrice: order.taxedPrice,
    lineItems: order.lineItems as Partial<Order['lineItems']> as Partial<
      CartFragment['lineItems']
    >,
  } as CartFragment)

  gaTrackEventPush({
    name: 'purchase',
    params: {
      currency: order.totalPrice?.currencyCode ?? '',
      transaction_id: transactionId,
      shipping: formatPrice(shipping),
      shipping_tier: order.shippingInfo?.shippingMethodName ?? '',
      cart_id: order.cart?.id,
      step: 5,
      value: formatPrice(total),
      coupon: concatDiscountCodesWithSpace(discounts ?? []),
      payment_type: params.paymentType ?? '',
      tax: formatPrice(tax),
      discount: formatPrice(discount),
      items,
    },
  })
}

export const gaTrackViewItem = (params: {
  product?: Partial<ProductFragment>
}) => {
  const { product } = params
  if (!product) {
    return
  }

  const price = product.masterVariant?.price

  // TODO: fix ts-ignore. Price types are not coming through
  gaTrackEventPush({
    name: 'view_item',
    params: {
      // @ts-ignore
      value: formatPrice(price?.value.centAmount),
      // @ts-ignore
      currency: price?.value.currencyCode ?? '',
      items: [
        {
          item_id: product?.id ?? '',
          item_name: product?.name ?? '',
          // @ts-ignore
          currency: price?.value.currencyCode ?? '',
          // @ts-ignore
          price: formatPrice(price?.value.centAmount),
          quantity: 1,
        },
      ],
    },
  })
}

export const gaTrackApplyCoupon = (params: {
  cart?: GaCartFragment
  couponCode?: string
}) => {
  const { cart, couponCode } = params
  if (!cart || !couponCode) {
    return
  }

  gaTrackEventPush({
    name: 'apply_coupon',
    params: {
      coupon: couponCode,
      currency: cart.totalPrice?.currencyCode ?? '',
      value: formatPrice(cart.totalPrice?.centAmount).toString(),
    },
  })
}

export const gaTrackRemoveCoupon = (params: { cart?: GaCartFragment }) => {
  const { cart } = params
  if (!cart) {
    return
  }

  gaTrackEventPush({
    name: 'remove_coupon',
    params: {
      currency: cart.totalPrice?.currencyCode ?? '',
      value: formatPrice(cart.totalPrice?.centAmount).toString(),
    },
  })
}

export const gaTrackUpdateLocation = (params: {
  currencyCode?: string
  countryCode?: string
}) => {
  const { currencyCode, countryCode } = params

  if (countryCode) {
    gaTrackEventPush({
      name: 'update_location',
      params: {
        country: countryCode,
      },
    })
  } else {
    gaTrackEventPush({
      name: 'update_location',
      params: {
        currency: currencyCode,
      },
    })
  }
}

type GaEventExtended =
  | GaEventsCollection
  | GaReviewOrderEvent
  | GaApplyCouponEvent
  | GaRemoveCouponEvent
  | GaUpdateLocationEvent

export const gaTrackEventPush = (event: any) => {
  // TODO: Fix type
  const dataLayer = (window as any).dataLayer

  if (dataLayer) {
    dataLayer.push({ event: event.name, ecommerce: event.params })
  }
}

export const gaTrackUserInformation = (
  pageType: string,
  properties: StatsDataLayer
) => {
  const dataLayer = (window as any).dataLayer

  if (dataLayer) {
    dataLayer.push({
      ...properties,
      event: EVENT_USER_INFORMATION,
      pageType: pageType,
    })
  }
}

export const gaTrackShippingInfo = (
  shippingAddress: Pick<
    Address,
    'firstName' | 'lastName' | 'postalCode' | 'state' | 'city' | 'phone'
  >
) => {
  const dataLayer = (window as any).dataLayer

  if (dataLayer) {
    dataLayer.push({
      event: 'shipping_info',
      firstName: shippingAddress.firstName ?? '',
      lastName: shippingAddress.lastName ?? '',
      zipCode: shippingAddress.postalCode ?? '',
      shippingState: shippingAddress.state ?? '',
      shippingCity: shippingAddress.city ?? '',
      phoneNumber: shippingAddress.phone ?? '',
    })
  }
}

export const gaTrackPageType = (pageType: string) => {
  const dataLayer = (window as any).dataLayer

  if (dataLayer) {
    dataLayer.push({
      event: 'page-type',
      pageType: pageType,
    })
  }
}

export const gaTrackPageView = (
  pageType: string,
  userStats: UserStats['data_layer'],
  cartId: string,
  guestCartId: string
) => {
  pushPageViewToDataLayer({
    pageType,
    userStats,
    cartId,
    guestCartId,
  })
}

export const gaTrackClearEcommerce = () => {
  const dataLayer = (window as any).dataLayer

  if (dataLayer) {
    dataLayer.push({
      ecommerce: null,
    })
  }
}
