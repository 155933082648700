export const cartLineItemFragment = /* GraphQL */ `
  fragment cartLineItem on LineItem {
    id
    productId
    productKey
    name(locale: $locale)
    productSlug(locale: $locale)
    quantity
    custom {
      customFieldsRaw {
        name
        value
      }
      type {
        fieldDefinitions {
          name
          label(locale: $locale)
          type {
            name
            ... on LocalizedEnumType {
              values {
                key
                label(locale: $locale)
              }
            }
          }
        }
      }
    }
    price {
      value {
        ...baseMoney
      }
      discounted {
        discount {
          id
          name(locale: $locale)
        }
        value {
          ...baseMoney
        }
      }
    }
    totalPrice {
      ...money
    }
    taxedPrice {
      ...taxedItemPrice
    }
    variant {
      id
      sku
      images {
        url
        dimensions {
          width
          height
        }
      }
      prices {
        value {
          ...money
        }
        discounted {
          value {
            ...money
          }
        }
      }
      attributesRaw {
        attributeDefinition {
          label(locale: $locale)
          type {
            name
          }
        }
        name
        value
      }
    }
    discountedPricePerQuantity {
      quantity
      discountedPrice {
        value {
          ...money
        }
        includedDiscounts {
          discount {
            id
            name(locale: $locale)
          }
          discountedAmount {
            ...baseMoney
          }
        }
      }
    }
    productType {
      name
    }
  }
`
