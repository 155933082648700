import { useCallback } from 'react'
import { useMutation } from '@tanstack/react-query'
import {
  accessTokenCustomerFetchService,
  customerLogInService,
} from '../../services'
import { useCommercetools } from '../../components'
import { gaTrackEventPush } from '@modules/commercetools'

export const useAuth = () => {
  const {
    httpClient,
    graphqlClient,
    clientId,
    clientSecret,
    projectKey,
    host,
    accessToken,
    setCustomerToken,
    setAnonymousToken,
  } = useCommercetools()

  const customerLogin = useMutation(
    ['useCustomerLogin'],

    async (variables: { email: string; password: string }) => {
      // this graphQL call performs merging the anonymous cart to our customer cart during sign in.
      await customerLogInService({
        httpClient,
        graphqlClient,
        token: accessToken,
        params: {
          variables,
        },
      })

      return await accessTokenCustomerFetchService({
        httpClient,
        params: {
          username: variables.email,
          password: variables.password,
          host,
          clientSecret,
          projectKey,
          clientId,
        },
      })
    },
    {
      onSuccess: (token) => {
        if (token) {
          setCustomerToken(token)
          setAnonymousToken(null)
          gaTrackEventPush({ name: 'login' })
        }
      },
    }
  )

  const logout = useCallback(() => {
    setAnonymousToken(null)
    setCustomerToken(null)
  }, [])

  return {
    login: customerLogin,
    logout,
  }
}
