import { useQuery } from '@tanstack/react-query'
import { useComposable } from '@modules/oriuminc/base'
import {
  getMegaMenuQuery,
  GetMegaMenuQuery,
  GetMegaMenuQueryVariables,
  contentfulGraphqlClient,
} from '../../utils'

export const getContentfulMegaMenuKey = (
  variables: GetMegaMenuQueryVariables
) => ['useContentfulMegaMenu', variables.locale ?? '', variables.id ?? '']

export const useContentfulMegaMenu = (id: string) => {
  const { locale } = useComposable()

  const query = useQuery(
    getContentfulMegaMenuKey({ locale, id }),
    () => {
      return contentfulMegaMenuFetchService({ locale, id })
    },
    {
      refetchOnMount: false,
      keepPreviousData: true,
    }
  )

  return {
    data: query.data,
    isNoMatch: query.data === null,
    isLoading: query.data === undefined,
    isLoaded: Boolean(query.data),
  }
}

export const contentfulMegaMenuFetchService = async (
  variables: GetMegaMenuQueryVariables
) => {
  const res = await contentfulGraphqlClient
    .query<GetMegaMenuQuery, GetMegaMenuQueryVariables>(
      getMegaMenuQuery,
      variables
    )
    .toPromise()

  return (
    res.data?.megaMenuCollection?.items?.[0]?.itemsCollection?.items ?? null
  )
}
