const disabledStyle = {
  backgroundColor: '#939A9D',
  borderColor: '#939A9D',
}

const checkboxStyle = {
  baseStyle: {
    control: {
      borderColor: 'gray.700',
      borderRadius: 'sm',
      borderWidth: '2px',

      _checked: {
        _disabled: disabledStyle,
      },

      _disabled: disabledStyle,

      _invalid: {
        borderColor: 'red.500',
      },
    },
  },
  defaultProps: {
    colorScheme: 'teal',
  },
}

export default checkboxStyle
