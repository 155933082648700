import { customerFragment } from '../fragments'

export const customerChangeMyPasswordMutation = /* GraphQL */ `
  mutation customerChangeMyPassword(
    $currentPassword: String!
    $newPassword: String!
    $storeKey: KeyReferenceInput
    $version: Long!
  ) {
    customerChangeMyPassword(
      currentPassword: $currentPassword
      newPassword: $newPassword
      storeKey: $storeKey
      version: $version
    ) {
      ...customer
    }
  }
  ${customerFragment}
`
