export const componentCoverCardFragment = /* GraphQL */ `
  fragment componentCoverCard on ComponentCoverCard {
    sys {
      id
    }
    eyebrow(locale: $locale)
    title(locale: $locale)
    image {
      ...contentfulAsset
    }
    content(locale: $locale) {
      json
    }
    href(locale: $locale)
    theme(locale: $locale)
    textAlign(locale: $locale)
    containerSize(locale: $locale)
    containerMarginTop(locale: $locale)
    containerMarginBottom(locale: $locale)
  }
`
