import {
  parseUrlTemplate,
  useComposable,
  useSiteConfig,
} from '@modules/oriuminc/base'
import { BrandPicker } from '@oriuminc/ui'

/**
 * banner for multi branded sites
 * the content is managed by contentful content type Site Config
 */
export const MultiBrandPicker = () => {
  const { sites, currentSite } = useSiteConfig()
  const { appKey } = useComposable()

  if (!appKey || !currentSite || !sites || sites.length < 2) {
    return null
  }

  return (
    <BrandPicker
      sites={sites.map((site) => ({
        ...site,
        url: parseUrlTemplate(site.url),
      }))}
      currentBrand={appKey}
      brandContainerProps={{
        filter: 'brightness(0) invert(0.95)',
      }}
    />
  )
}
