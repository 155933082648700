import { useToast as useChakraToast, UseToastOptions } from '@chakra-ui/react'

export const useToast = (options?: UseToastOptions) => {
  return useChakraToast({
    containerStyle: {
      // @ts-ignore: This is valid styling, but the typing does not reflect it.
      // Check the Chakra UI docs for an example just like this:
      // https://chakra-ui.com/docs/components/toast/usage#custom-container-styles
      '& > .chakra-alert': {
        paddingInlineEnd: '4',
      },

      // @ts-ignore: See above.
      '& button': {
        marginInlineEnd: '-3',
        marginInlineStart: '3',
        position: 'relative',
        top: 'auto',
      },
    },
    duration: 2500,
    isClosable: true,
    position: 'top',
    variant: 'subtle',
    ...options,
  })
}
