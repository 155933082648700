import { variantFragment } from './variantFragment'

export const productFragement = /* GraphQL */ `
  fragment product on ProductProjection {
    version
    id
    key
    slug(locale: $locale)
    name(locale: $locale)
    description(locale: $locale)
    productTypeRef {
      typeId
      id
    }
    masterVariant {
      ...variant
    }
    variants {
      ...variant
    }
  }
  ${variantFragment}
`
