import type { Discount } from './types'

export function mergeDiscountsByName(discounts: Discount[]): Discount[] {
  const result = [] as Discount[]
  const map = {} as Record<string, Discount>

  discounts.forEach((discount) => {
    if (!map[discount.name]) {
      map[discount.name] = { name: discount.name, value: discount.value, hide: discount.hide }
      result.push(map[discount.name])
    } else {
      map[discount.name].value += discount.value
    }
  })

  return result
}
