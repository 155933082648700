import { ReactElement } from 'react'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { Box } from '@chakra-ui/react'
import { MultiBrandPicker } from '@modules/app'
import { useComposable } from '@modules/oriuminc/base'
import { cmsProvider } from '@modules/cms'
import { useUser } from '@modules/commercetools'
import { PATHS } from 'constants/paths'
import { CartHeader } from '../Headers/CartHeader'

const DynamicCartDrawer = dynamic((): any =>
  import('@modules/cart').then((_module) => _module.CartDrawer)
)

const DynamicAccountDrawer: any = dynamic((): any =>
  import('@modules/app').then((_module) => _module.AccountDrawer)
)

interface LayoutProps {
  children: ReactElement | ReactElement[]
}

export const Layout = ({ children }: LayoutProps) => {
  const router = useRouter()
  const { logout, customer } = useUser()
  const { path, cartDrawer, accountDrawer } = useComposable()
  const isCheckout = router?.pathname === path.getCheckout()
  const { MegaMenu, MegaDrawer, Footer } = cmsProvider

  if (isCheckout) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        minHeight="100vh"
        bg={'#FAFAFA'}
      >
        <Box flexGrow={1}>{children}</Box>
      </Box>
    )
  }

  return (
    <Box display="flex" flexDirection="column" minHeight="100vh">
      <MultiBrandPicker />
      <CartHeader />
      {MegaMenu}
      <Box flexGrow={1}>{children}</Box>
      {Footer}
      <span id="zendesk-live-chat" />
      {/* temporary until we have a footer element to attach this GTM ID */}
      {accountDrawer.isOpen && (
        <DynamicAccountDrawer isLoggedIn={Boolean(customer)} logout={logout} />
      )}
      {cartDrawer.isOpen && <DynamicCartDrawer />}
      {MegaDrawer}
    </Box>
  )
}
