import {
  AdultContent,
  AlpCurrency,
  Country,
  MeasurementSystem,
  Preferences,
  User,
} from '@modules/spoonflower/utils/graphql/generated/graphql'
import { useQueryClient } from '@tanstack/react-query'
import useUpdateUserPreferences from './useUserPreferences/useUpdateUserPreferences'
import useWhoAmI, { useWhoAmIKey } from './useWhoAmI'

interface DefaultPreferences extends Preferences {
  adult_content: AdultContent
  country: Country
  currency: AlpCurrency
  locale: string
  measurement_system: MeasurementSystem
}

const defaults: Readonly<DefaultPreferences> = {
  adult_content: AdultContent.AdultContentOff,
  country: Country.Us,
  currency: AlpCurrency.Usd,
  locale: 'en-US',
  measurement_system: MeasurementSystem.Imperial,
}

const useUserPreferences = () => {
  const queryClient = useQueryClient()
  const updatePreferences = useUpdateUserPreferences()
  const { data: user } = useWhoAmI()
  const { preferences = {} } = user ?? {}
  const { adult_content, country, currency, locale, measurement_system } =
    preferences ?? {}

  const modifiedMutate: typeof updatePreferences.mutate = (
    variables,
    options
  ) => {
    const modifiedOptions: typeof options = {
      ...options,
      onSuccess: (data, variables, context) => {
        const user = queryClient.getQueryData<User>(useWhoAmIKey)

        // Update the "useWhoAmI" cached data.
        queryClient.setQueryData(useWhoAmIKey, {
          ...user,
          preferences: data,
        })

        // Fire any "onSuccess" handlers that may have been specified.
        options?.onSuccess?.(data, variables, context)
      },
    }

    return updatePreferences.mutate(variables, modifiedOptions)
  }

  const modifiedUpdatePreferences = {
    ...updatePreferences,
    mutate: modifiedMutate,
  }

  return {
    preferences: {
      adult_content: adult_content ?? defaults.adult_content,
      country: country ?? defaults.country,
      currency: currency ?? defaults.currency,
      locale: locale ?? defaults.locale,
      measurement_system: measurement_system ?? defaults.measurement_system,
    },
    updatePreferences: modifiedUpdatePreferences,
  }
}

export { defaults, useUserPreferences as default }
