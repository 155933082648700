import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { ComponentTextCardFragment } from '../../utils'
import { TextCard } from '@oriuminc/ui'

export const ComponentTextCard = ({
  image,
  title,
  content,
  ctaLabel,
  ctaHref,
  textAlign,
  theme,
}: ComponentTextCardFragment) => {
  return (
    <TextCard
      image={{
        src: image?.url ?? '',
        alt: image?.title ?? '',
      }}
      title={{
        children: title ?? '',
      }}
      description={{
        children: content?.json
          ? documentToReactComponents(content?.json)
          : undefined,
      }}
      button={{
        children: ctaLabel ?? '',
        href: ctaHref ?? '',
        whiteSpace: 'normal',
      }}
      textAlign={textAlign as any}
      theme={theme as any}
    />
  )
}
