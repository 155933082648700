import { default as chakraTheme } from '@chakra-ui/theme'

/**
 * Spacing documentation:
 * https://chakra-ui.com/docs/styled-system/theme#spacing
 *
 * This allows you to customize the global spacing and sizing scale for your project.
 * By default, these spacing values can be referenced by the `padding`, `margin`,
 * and `top`, `left`, `right`, `bottom` styles.
 */

const spacing = {
  ...chakraTheme.space,

  px: '1px',
  0: '0rem',
  0.5: '0.125rem',
  1: '0.25rem',
  1.5: '0.375rem',
  2: '0.5rem',
  2.5: '0.625rem',
  3: '0.75rem',
  3.5: '0.875rem',
  4: '1rem',
  5: '1.25rem',
  6: '1.5rem',
  7: '1.75rem',
  8: '2rem',
  9: '2.25rem',
  10: '2.5rem',
  12: '3rem',
  14: '3.5rem',
  16: '4rem',
  20: '5rem',
  24: '6rem',
  28: '7rem',
  32: '8rem',
  36: '9rem',
  40: '10rem',
  44: '11rem',
  48: '12rem',
  52: '13rem',
  56: '14rem',
  60: '15rem',
  64: '16rem',
  72: '18rem',
  80: '20rem',
  96: '24rem',

  // #region Orium theme
  /**
   * !!! DO NOT USE THESE VALUES !!!
   *
   * TODO: Convert usages of these values to their default theme counterparts, or a reasonable alternative.
   * Ex. /src/modules/app/pages/ProductListingPage/Parts/CategoryProductCard.tsx#47
   * 'xxxxs' to '0.5'
   */
  xxxxs: '0.125rem', // 2px
  xxxs: '0.25rem', // 4px
  xxs: '0.5rem', // 8px
  xs: '0.75rem', // 12px
  sm: '1rem', // 16px
  md: '1.5rem', // 24px
  lg: '2rem', // 32px
  'lg-2': '2.5rem', // 40px
  xl: '3rem', // 48px
  xxl: '4rem', // 64px
  xxxl: '6rem', // 96px
  xxxxl: '8rem', // 128px

  // grid
  gridGap: '1.25rem', // 20px
  gridRowGap: '20px',
  gridColGap: '20px',
  // #endregion
} as const

export default spacing
