import { default as chakraTheme } from '@chakra-ui/theme'

/**
 * Border radii documentation
 * https://chakra-ui.com/docs/styled-system/theme#border-radius
 */

export const radii = {
  ...chakraTheme.radii,

  none: '0',
  sm: '0.125rem',
  base: '0.25rem',
  md: '0.375rem',
  lg: '0.5rem',
  xl: '0.75rem',
  '2xl': '1rem',
  '3xl': '1.5rem',
  full: '9999px',
} as const

export default radii
