export const cartFragment = /* GraphQL */ `
  fragment cart on Cart {
    id
    version
    customerId
    customer {
      ...customer
    }
    customerEmail
    custom {
      customFieldsRaw {
        name
        value
      }
    }
    anonymousId
    cartState
    totalLineItemQuantity
    lineItems {
      ...cartLineItem
    }
    totalPrice {
      ...money
    }
    taxedPrice {
      ...taxedPrice
    }
    shippingAddress {
      ...address
    }
    billingAddress {
      ...address
    }
    paymentInfo {
      payments {
        paymentMethodInfo {
          paymentInterface
          method
        }
        transactions {
          id
          interactionId
          state
        }
      }
    }
    discountCodes {
      discountCodeRef {
        id
        typeId
      }
      discountCode {
        id
        name(locale: $locale)
        code
        isActive
        cartDiscounts {
          id
          cartPredicate
          target {
            type
          }
        }
      }
      state
    }
    shippingInfo {
      shippingMethodName
      price {
        ...money
      }
      shippingMethod {
        id
        key
      }
      discountedPrice {
        value {
          ...baseMoney
        }
        includedDiscounts {
          discount {
            id
            name(locale: $locale)
          }
          discountedAmount {
            ...baseMoney
          }
        }
      }
    }
  }
`
