import { siteConfigFragment } from '../fragments'

export const getSiteConfigQuery = /* GraphQL */ `
  query getSiteConfig($locale: String, $key: String) {
    siteConfigCollection(where: { key: $key }) {
      items {
        ...siteConfig
      }
    }
  }

  ${siteConfigFragment}
`
