import { CartDrawer } from '@modules/cart'
import {
  CartUpdateAction,
  LineItem,
  StagedOrderUpdateAction,
} from '@oriuminc/commercetools'
import { CtServiceDeps } from '../../types'
import {
  AddressInput,
  CreateMyCartMutation,
  CreateMyCartMutationVariables,
  CustomFieldsDraft,
  GetActiveCartQuery,
  GetCartQuery,
  GetCartQueryVariables,
  GetCartsQuery,
  GetCartsQueryVariables,
  Maybe,
  MyCartUpdateAction,
  UpdateMyCartMutation,
  UpdateMyCartMutationVariables,
} from '../../types/commercetools-schema'
import { getAuthHeader } from '../../utils'
import { GraphqlClientRequired } from '../../utils/GraphqlClientRequired'
import {
  createMyCartMutation,
  updateMyCartMutation,
} from '../../utils/mutations'
import { getActiveCartQuery, getCartQuery, getCartsQuery } from '../../utils/queries'

export const cartCreateService = async ({
  graphqlClient,
  params,
  token,
}: CtServiceDeps<{
  currency: string
  locale: string
  customerEmail?: string
}>) => {
  if (!graphqlClient) {
    throw new GraphqlClientRequired()
  }

  if (!params) {
    throw new Error('params required')
  }

  const res = await graphqlClient
    .mutation<CreateMyCartMutation, CreateMyCartMutationVariables>(
      createMyCartMutation,
      {
        locale: params.locale,
        draft: {
          currency: params.currency,
          customerEmail: params.customerEmail,
        },
      },
      {
        fetchOptions: {
          headers: {
            authorization: getAuthHeader(token),
          },
        },
      }
    )
    .toPromise()

  if (res.error) {
    throw new Error(res.error.graphQLErrors[0]?.message || res.error.message)
  }

  return res.data?.createMyCart
}

export const cartItemAddService = async ({
  graphqlClient,
  params,
  token,
}: CtServiceDeps<{
  cartId: string
  version?: number
  productId: string
  variantId?: number
  quantity?: number
  locale: string
  custom?: Maybe<CustomFieldsDraft>
}>) => {
  if (!params) {
    throw new Error('params required')
  }

  return cartUpdateService({
    graphqlClient,
    token,
    params: {
      variables: {
        id: params.cartId,
        version: params.version ?? 1,
        locale: params.locale,
        actions: {
          addLineItem: {
            productId: params?.productId,
            variantId: params?.variantId,
            quantity: params?.quantity ?? 1,
            custom: params?.custom,
          },
        },
      },
    },
  })
}

export const cartItemUpdateService = async ({
  graphqlClient,
  params,
  token,
}: CtServiceDeps<{
  cartId: string
  version?: number
  lineItemId: string
  quantity: number
  locale: string
}>) => {
  if (!params) {
    throw new Error('params required')
  }

  return cartUpdateService({
    graphqlClient,
    token,
    params: {
      variables: {
        id: params.cartId,
        version: params.version,
        locale: params.locale,
        actions: {
          changeLineItemQuantity: {
            lineItemId: params.lineItemId,
            quantity: params.quantity,
          },
        },
      },
    },
  })
}

export const cartItemDeleteService = async ({
  graphqlClient,
  params,
  token,
}: CtServiceDeps<{
  cartId: string
  version?: number
  lineItemId: string
  locale: string
}>) => {
  if (!params) {
    throw new Error('required params missing')
  }

  return cartUpdateService({
    graphqlClient,
    token,
    params: {
      variables: {
        id: params.cartId,
        version: params.version,
        locale: params.locale,
        actions: {
          removeLineItem: {
            lineItemId: params.lineItemId,
          },
        },
      },
    },
  })
}

export const cartAddressUpdateService = async ({
  graphqlClient,
  params,
  token,
}: CtServiceDeps<{
  cartId: string
  version?: number
  shippingAddress?: AddressInput
  billingAddress?: AddressInput
  locale: string
}>) => {
  if (!params) {
    throw new Error('required params missing')
  }

  const actions = <MyCartUpdateAction[]>[]
  if (params?.shippingAddress) {
    actions.push({
      setShippingAddress: {
        address: params.shippingAddress,
      },
    })
  }
  if (params?.billingAddress) {
    actions.push({
      setBillingAddress: {
        address: params.billingAddress,
      },
    })
  }

  return cartUpdateService({
    graphqlClient,
    token,
    params: {
      variables: {
        id: params.cartId,
        version: params.version,
        locale: params.locale,
        actions,
      },
    },
  })
}

export const cartUpdateService = async ({
  graphqlClient,
  token,
  params,
}: CtServiceDeps<{
  variables: UpdateMyCartMutationVariables
}>) => {
  if (!graphqlClient) {
    throw new GraphqlClientRequired()
  }

  if (!params?.variables) {
    throw new Error('cartId and version are required')
  }

  const res = await graphqlClient
    .mutation<UpdateMyCartMutation, UpdateMyCartMutationVariables>(
      updateMyCartMutation,
      {
        ...params.variables,
      },
      {
        fetchOptions: {
          headers: {
            authorization: getAuthHeader(token),
          },
        },
      }
    )
    .toPromise()

  if (res.error) {
    throw new Error(res.error.graphQLErrors[0]?.message || res.error.message)
  }

  return res.data?.updateMyCart
}

export const cartFetchService = async ({
  graphqlClient,
  token,
}: CtServiceDeps) => {
  if (!graphqlClient) {
    throw new GraphqlClientRequired()
  }
  const res = await graphqlClient
    .query<GetActiveCartQuery>(
      getActiveCartQuery,
      {},
      {
        fetchOptions: {
          headers: {
            authorization: getAuthHeader(token),
          },
        },
      }
    )
    .toPromise()

  if (res.error) {
    throw new Error(res.error.graphQLErrors[0]?.message || res.error.message)
  }

  return res.data?.me.activeCart
}

export const cartByIdFetchService = async ({
  graphqlClient,
  params,
  token,
}: CtServiceDeps<{
  variables: GetCartQueryVariables
}>) => {
  if (!graphqlClient) {
    throw new GraphqlClientRequired()
  }

  const res = await graphqlClient
    .query<GetCartQuery, GetCartQueryVariables>(
      getCartQuery,
      {
        id: params?.variables.id ?? '',
        locale: params?.variables.locale,
      },
      {
        fetchOptions: {
          headers: {
            authorization: getAuthHeader(token),
          },
        },
      }
    )
    .toPromise()

  if (res.error) {
    throw new Error(res.error.graphQLErrors[0]?.message || res.error.message)
  }

  return res.data?.cart
}
