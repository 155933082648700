import { useQuery } from '@tanstack/react-query'
import { useComposable } from '@modules/oriuminc/base'
import {
  getPageQuery,
  GetPageQuery,
  GetPageQueryVariables,
  contentfulGraphqlClient,
} from '../../utils'

export const getContentfulPageKey = (slug: string, locale: string) => [
  'useContentfulPage',
  slug ?? '',
  locale ?? '',
]

export const useContentfulPage = (slug: string) => {
  const { locale } = useComposable()

  const query = useQuery(
    getContentfulPageKey(slug, locale),
    () => {
      return contentfulPageFetchService({ slug, locale })
    },
    {
      keepPreviousData: true,
    }
  )

  return {
    data: query.data,
    isNoMatch: query.data === null,
    isLoading: query.data === undefined,
    isLoaded: Boolean(query.data),
  }
}

export const contentfulPageFetchService = async (
  variables: GetPageQueryVariables
) => {
  const res = await contentfulGraphqlClient
    .query<GetPageQuery, GetPageQueryVariables>(getPageQuery, variables)
    .toPromise()

  return res.data?.pageCollection?.items[0] ?? null
}
