export const componentCtConnectorFragment = /* GraphQL */ `
  fragment componentCtConnector on ComponentCtConnector {
    sys {
      id
    }
    title(locale: $locale)
    ctaHref(locale: $locale)
    ctaLabel(locale: $locale)
    productList
    containerSize(locale: $locale)
    containerMarginTop(locale: $locale)
    containerMarginBottom(locale: $locale)
  }
`
