import { StyleFunctionProps } from '@chakra-ui/styled-system'
import { Button } from '@chakra-ui/theme/components'

const defaultDisabledStyling = {
  backgroundColor: 'gray.100',
  color: 'gray.400',
  opacity: 1,
}

const outlineVariant = (props: StyleFunctionProps) => {
  const outline = Button.variants?.outline(props)
  const { colorScheme } = props
  const color = 'gray.700'
  const backgroundColor = 'white'
  const disabledStyling = {
    ...defaultDisabledStyling,
    backgroundColor,
    borderColor: 'gray.300',
    color: 'gray.300',
  }

  return {
    ...outline,

    color,
    backgroundColor,
    borderColor: 'gray.200',
    boxShadow: 'base !important',
    textDecoration: 'none',

    _active: {
      ...outline?._active,
      backgroundColor,
      borderColor: 'gray.700',
    },
    _disabled: {
      ...disabledStyling,

      _active: disabledStyling,
      _hover: disabledStyling,
    },
    _hover: {
      ...outline?._hover,
      backgroundColor: 'white',
      color,
      borderColor: 'gray.500',
      textDecoration: 'none',
    },
  }
}

const solidVariant = (props: StyleFunctionProps) => {
  const solid = Button.variants?.solid(props)

  return {
    ...solid,

    /**
     * When using "as={Link}" for button-shaped links,
     * we need to ensure that Link styling doesn't override Button styling.
     */
    textDecoration: 'none',

    _hover: {
      ...solid?._hover,

      /**
       * When using "as={Link}" for button-shaped links,
       * we need to ensure that Link styling doesn't override Button styling.
       */
      color: solid?.color,
      textDecoration: 'none',
    },
  }
}

const buttonStyles = {
  baseStyle: {
    borderRadius: 'md',
    _disabled: {
      ...defaultDisabledStyling,

      _active: defaultDisabledStyling,
      _hover: defaultDisabledStyling,
    },
  },
  sizes: {
    xs: {
      fontWeight: 'semibold',
      height: '24px',
      textStyle: 'sm',
    },
    sm: {
      fontWeight: 'semibold',
      height: '32px',
      textStyle: 'md',
    },
    md: {
      fontWeight: 'semibold',
      height: '40px',
      textStyle: 'md',
    },
    lg: {
      fontWeight: 'semibold',
      height: '48px',
      textStyle: 'lg',
      lineHeight: '125%',
      letterSpacing: 0.2,
    },
  },
  variants: {
    // #region Orium theme
    'solid-alt': {
      color: 'text',
      background: 'background',
      _hover: {
        bg: 'background',
        _disabled: {
          bg: 'text-muted',
        },
      },
      _active: {
        bg: 'background',
      },
      _disabled: {
        opacity: 1,
        bg: 'text-muted',
      },
    },
    'outline-black': {
      color: 'text',
      borderColor: '#E2E8F0',
      borderWidth: '1px',
      borderStyle: 'solid',
      _hover: {
        bg: 'muted',
        borderColor: 'primary',
        _disabled: {
          color: 'muted',
          borderColor: 'muted',
        },
      },
      _active: {
        bg: 'accent',
        borderColor: 'accent',
      },
      _disabled: {
        opacity: 1,
        color: 'text-muted',
        borderColor: 'text-muted',
      },
    },
    'outline-alt': {
      color: 'background',
      borderColor: 'background',
      borderWidth: '1px',
      borderStyle: 'solid',
      _hover: {
        bg: 'text',
        borderColor: 'background',
        _disabled: {
          color: 'text-muted',
          borderColor: 'text-muted',
        },
      },
      _active: {
        bg: 'background',
        borderColor: 'background',
      },
      _disabled: {
        opacity: 0.5,
        color: 'text-muted',
        borderColor: 'text-muted',
      },
    },
    'ghost-alt': {
      color: 'white',
      borderColor: 'white',
      textDecoration: 'underline',
      textUnderlineOffset: '3px',
      __before: {
        content: '" "',
        display: 'block',
        width: '10px',
        height: '10px',
        background: 'red',
      },
      _hover: {
        bg: 'background',
        borderColor: 'text-muted',
      },
      _active: {
        bg: 'background',
        borderColor: 'text-muted',
      },
      _disabled: {
        opacity: 1,
        color: 'text-muted',
        borderColor: 'text-muted',
      },
    },
    danger: {
      color: 'white',
      background: 'bg-danger',
    },
    success: {
      color: 'white',
      background: 'bg-success',
    },
    muted: {
      color: 'fg-btn-muted',
      background: 'bg-btn-muted',
    },
    // #endregion

    // #region Spoonflower theme
    'cart-product-card-base': (props: StyleFunctionProps) => {
      const style = outlineVariant(props)

      return {
        ...style,

        borderColor: 'gray.200',
        borderRadius: 'none',
        boxShadow: 'none',
        flexShrink: '2',

        _active: {
          ...style._active,
        },

        _disabled: {
          ...style._disabled,
        },

        _hover: {
          ...style._hover,

          zIndex: '1',
        },
      }
    },
    ghost: {
      textDecoration: 'underline',
      _hover: {
        backgroundColor: 'gray.50',
        borderColor: 'transparent',
        color: 'teal.700',
      },
      _active: {
        backgroundColor: 'gray.100',
        borderColor: 'transparent',
        color: 'teal.600',
      },
      _disabled: {
        backgroundColor: 'transparent',
        borderColor: 'transparent',
        color: 'gray.400',
      },
    },
    link: {
      borderBottom: '1px',
      color: 'gray.700',
      lineHeight: '6',
      borderRadius: 0,
      borderColor: 'gray.700',
      textDecoration: 'none',
      fontSize: { base: 'xs', md: 'sm' },
      paddingInline: 4,
      _hover: {
        textDecoration: 'none',
      },
      _disabled: {
        cursor: 'not-allowed',
      },
    },
    outline: outlineVariant,
    'quantity-picker-addon': {
      backgroundColor: 'teal.50',
      border: '1px solid',
      borderColor: 'teal.100',

      color: 'teal.500',
      _active: {
        backgroundColor: 'teal.100',
        color: 'teal.700',
        borderColor: 'teal.100',
      },
      _hover: {
        color: 'white',
        backgroundColor: 'teal.500',
        borderColor: 'teal.500',
      },
      _disabled: {
        backgroundColor: 'white',
        borderColor: 'gray.300',
        color: 'gray.300',
        _hover: {
          background: 'white !important',
          borderColor: 'gray.300',
          color: 'gray.300',
        },
      },
      minWidth: '36px',
    },
    transparent: {
      backgroundColor: 'transparent',
      _hover: {
        backgroundColor: 'transparent',
      },
      _focus: {
        backgroundColor: 'transparent',
      },
      _active: {
        backgroundColor: 'transparent',
      },
    },
    solid: solidVariant,
    'solid-black': {
      backgroundColor: 'gray.500',
      color: 'warm-white',

      _hover: {
        backgroundColor: 'gray.600',
      },
      _active: {
        backgroundColor: 'gray.700',
      },
      _pressed: {
        backgroundColor: 'gray.700',
      },
      _disabled: {
        backgroundColor: 'gray.100',
        color: 'gray.400',
        _hover: {
          background: 'gray.100 !important', // using `important` to override the default state provided by Chakra
          color: 'gray.400',
        },
      },
    },
    'link-teal': {
      color: 'link-text',
      fontWeight: 'semibold',
      textDecoration: 'underline',
      textUnderlineOffset: '3px',
      textDecorationThickness: '1px',
      letterSpacing: 0.2,

      _hover: {
        color: 'link-text-hover',
      },
    },
  },
}

export default buttonStyles
