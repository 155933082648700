export const megaMenuItemFragment = /* GraphQL */ `
  fragment megaMenuItem on MegaMenuItem {
    sys {
      id
    }
    variant(locale: $locale)
    label(locale: $locale)
    href(locale: $locale)
    description(locale: $locale)
    imagesCollection(locale: $locale) {
      items {
        title(locale: $locale)
        description(locale: $locale)
        url(locale: $locale)
      }
    }
  }
`
