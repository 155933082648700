import { default as chakraTheme } from '@chakra-ui/theme'

const cardStyles = {
  baseStyle: {
    container: {
      backgroundColor: 'warm-white',
    },
  },
  variants: {
    'blog-card': {
      ...chakraTheme.components.Card.variants?.elevated,

      body: {
        paddingBottom: '3',
      },
      container: {
        ...chakraTheme.components.Card.variants?.elevated.container,

        width: '100%',
      },
      header: {
        padding: '0',
      },
      footer: {
        paddingTop: '3',
      },
    },
    'checkout-detail-item': {
      ...chakraTheme.components.Card.variants?.elevated,

      body: {
        marginTop: '2',
        padding: '0',
      },
      container: {
        ...chakraTheme.components.Card.variants?.elevated.container,

        paddingX: { base: '6', lg: '12' },
        paddingY: { base: '6', lg: '8' },
      },
      header: {
        margin: '0',
        marginBottom: '2',
        padding: '0',
      },
    },
  },
}

export default cardStyles
