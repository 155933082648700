import { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useLocalStorage } from '@oriuminc/base'

import { LOCAL_STORAGE_CT_ACCESS_TOKEN_ANONYMOUS } from '../../constants'
import { CtAccessTokenInterface } from '../../types'
import {
  accessTokenAnonymousFetchService,
  accessTokenRefreshService,
} from '../../services'
import { CommercetoolsContextInterface } from '../../components'

export const useAnonymousTokenKey = 'useAnonymousToken'

/***
 * DEPRECATED
 * This came with the starter kit from Orium, but we will not be using it. @Divis July 2023
 */

export const useAnonymousToken = (params: {
  commercetoolsContext: CommercetoolsContextInterface
}) => {
  // const { commercetoolsContext } = params
  // const [refetchInterval, setRefetchInterval] = useState<number | undefined>()
  // const [anonymousToken] = useLocalStorage<CtAccessTokenInterface | null>(
  //   LOCAL_STORAGE_CT_ACCESS_TOKEN_ANONYMOUS,
  //   null
  // )

  // useQuery(
  //   [useAnonymousTokenKey],
  //   async () => {
  //     const fetchAnonymousToken = async () => {
  //       return accessTokenAnonymousFetchService({
  //         httpClient: commercetoolsContext.httpClient,
  //         params: {
  //           projectKey: commercetoolsContext.projectKey,
  //           clientId: commercetoolsContext.clientId,
  //           clientSecret: commercetoolsContext.clientSecret,
  //           host: commercetoolsContext.host,
  //         },
  //       })
  //     }

  //     if (anonymousToken?.refresh_token) {
  //       try {
  //         const refreshToken = await accessTokenRefreshService({
  //           httpClient: commercetoolsContext.httpClient,
  //           params: {
  //             token: anonymousToken?.refresh_token ?? '',
  //             projectKey: commercetoolsContext.projectKey,
  //             clientId: commercetoolsContext.clientId,
  //             clientSecret: commercetoolsContext.clientSecret,
  //             host: commercetoolsContext.host,
  //           },
  //         })

  //         return {
  //           ...refreshToken,
  //           refresh_token: anonymousToken?.refresh_token,
  //         }
  //       } catch {
  //         return await fetchAnonymousToken()
  //       }
  //     }

  //     return await fetchAnonymousToken()
  //   },
  //   {
  //     retry: false,
  //     refetchInterval,
  //     onSuccess: (data) => {
  //       commercetoolsContext.setAnonymousToken(data)
  //       setRefetchInterval(data.expires_in * 1000 - 60 * 1000)
  //     },
  //   }
  // )
}
