import { useIntl } from 'react-intl'

export const useHeaderOptions = () => {
  const intl = useIntl()

  const initialOptionsState = [
    {
      id: 1,
      key: 'step1',
      hash: '',
      isAllowed: true,
      name: intl.formatMessage({ id: 'checkout.header.options.shipping' }),
      title: intl.formatMessage({ id: 'checkout.header.options.shipping' }),
    },
    {
      id: 2,
      key: 'step2',
      hash: 'billing',
      isAllowed: true,
      name: intl.formatMessage({ id: 'checkout.header.options.payment' }),
      title: intl.formatMessage({ id: 'checkout.header.options.payment' }),
    },
    {
      id: 3,
      key: 'review',
      hash: 'review',
      isAllowed: true,
      name: intl.formatMessage({ id: 'checkout.header.options.review' }),
      title: intl.formatMessage({ id: 'checkout.header.options.review' }),
    },
  ]

  return initialOptionsState
}
