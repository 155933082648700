export const customerCreatePasswordResetTokenMutation = /* GraphQL */ `
  mutation customerCreatePasswordResetToken($email: String!) {
    customerCreatePasswordResetToken(email: $email) {
      id
      value
      expiresAt
      version
    }
  }
`
