import {
  componentArticleCardFragment,
  componentBannerFullFragment,
  componentBannerSplitFragment,
  componentBannerTextOnlyFragment,
  componentCoverCardFragment,
  componentEpConnectorFragment,
  componentCtConnectorFragment,
  componentGridFragment,
  componentTextCardFragment,
  contentfulAssetFragment,
} from '../fragments'

export const getPageQuery = /* GraphQL */ `
  query getPage($slug: String, $locale: String) {
    pageCollection(where: { slug: $slug }, limit: 1) {
      items {
        metaTitle(locale: $locale)
        metaDescription(locale: $locale)
        metaKeywords(locale: $locale)
        slug
        contentCollection {
          items {
            ... on ComponentBannerFull {
              ...componentBannerFull
            }
            ... on ComponentBannerSplit {
              ...componentBannerSplit
            }
            ... on ComponentBannerTextOnly {
              ...componentBannerTextOnly
            }
            ... on ComponentCoverCard {
              ...componentCoverCard
            }
            ... on ComponentGrid {
              ...componentGrid
            }
            ... on ComponentEpConnector {
              ...componentEpConnector
            }
            ... on ComponentCtConnector {
              ...componentCtConnector
            }
          }
        }
      }
    }
  }
  ${componentArticleCardFragment}
  ${componentBannerFullFragment}
  ${componentBannerSplitFragment}
  ${componentBannerTextOnlyFragment}
  ${componentCoverCardFragment}
  ${componentEpConnectorFragment}
  ${componentCtConnectorFragment}
  ${componentGridFragment}
  ${componentTextCardFragment}
  ${contentfulAssetFragment}
`
