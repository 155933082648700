export const imagesFragment = /* GraphQL */ `
  fragment images on ImageProductSearch {
    url
    label
    dimensions {
      width
      height
    }
  }
`
