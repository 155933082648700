import { useEffect, useState } from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useRouter } from 'next/router'
import {
  ComposableContextInterface,
  useComposable,
} from '@modules/oriuminc/base'

import { LOCAL_STORAGE_CT_ACCESS_TOKEN_CUSTOMER } from '../../constants'
import { CtAccessTokenInterface } from '../../types'
import { useUserKey } from '../useUser'
import { CommercetoolsContextInterface } from '../../components'
import { accessTokenRefreshService } from '../../services'
import { useClientSideCookie } from '@modules/cart/hooks/useClientSideCookie'
import axios from 'axios'
import { useLocalStorage } from '@oriuminc/base'

export const useUserAuthHandler = (params: {
  commercetoolsContext: CommercetoolsContextInterface
}) => {
  const { commercetoolsContext } = params
  const router = useRouter()
  const accessToken = useClientSideCookie()
  const queryClient = useQueryClient()
  const { path } = useComposable()
  const [refetchInterval, setRefetchInterval] = useState<number | undefined>()
  const [customerToken] = useLocalStorage<CtAccessTokenInterface>(
    LOCAL_STORAGE_CT_ACCESS_TOKEN_CUSTOMER
  )
  const customerTokenValue = customerToken?.access_token
  const isPathnameForbidden =
    customerTokenValue && getIsPathnameForbidden(path, router.asPath)

  // Invalidate user query when not logged in
  useEffect(() => {
    if (!customerTokenValue) {
      queryClient.invalidateQueries([useUserKey], { exact: true })
    }
  }, [customerTokenValue])

  // Redirect user when logged in
  useEffect(() => {
    if (isPathnameForbidden) {
      router.replace(path.getHome())
    }
  }, [router, isPathnameForbidden, path])

  /***
   * DEPRECATED
   * This came with the starter kit from Orium, but we will not be using it. @Divis July 2023
   */
  // Refresh customer token
  // useQuery(
  //   ['customerTokenRefresh'],
  //   async () => {
  //     const refreshToken = await accessTokenRefreshService({
  //       httpClient: commercetoolsContext.httpClient,
  //       params: {
  //         token: accessToken?.refresh_token ?? '',
  //         projectKey: commercetoolsContext.projectKey,
  //         clientId: commercetoolsContext.clientId,
  //         clientSecret: commercetoolsContext.clientSecret,
  //         host: commercetoolsContext.host,
  //       },
  //     })

  //     return {
  //       ...refreshToken,
  //       refresh_token: accessToken?.refresh_token,
  //     }
  //   },
  //   {
  //     retry: false,
  //     refetchInterval,
  //     enabled: !!accessToken?.access_token,
  //     onSuccess: async (data) => {
  //       await axios.post('/api/token/set-cookie', { token: data })
  //       setRefetchInterval(data.expires_in * 1000 - 60 * 1000)
  //     },
  //     onError: () => {
  //       commercetoolsContext.setCustomerToken(null)
  //     },
  //   }
  // )
}

const getIsPathnameForbidden = (
  path: ComposableContextInterface['path'],
  pathname: string
): boolean => {
  const forbiddenPathsWhileLoggedIn = [
    path.getAccountLogin(),
    path.getAccountRegister(),
    path.getAccountForgot(),
    path.getAccountReset(),
  ]

  return forbiddenPathsWhileLoggedIn.some(
    (path) => pathname.indexOf(path) === 0
  )
}
