export const customerSignMeUpMutation = /* GraphQL */ `
  mutation customerSignMeUp(
    $email: String!
    $password: String!
    $firstName: String
    $lastName: String
  ) {
    customerSignMeUp(
      draft: {
        email: $email
        password: $password
        firstName: $firstName
        lastName: $lastName
      }
    ) {
      customer {
        id
      }
    }
  }
`
