import { useEffect, useState } from 'react'

type UserStats = {
  data: {
    cookie_value: string
    data_layer: StatsDataLayer
  }
}

export type StatsDataLayer = {
  accountAge?: string
  contestVoter?: string
  countryCode?: string
  currencyCode?: string
  customerStatus?: string
  designForSale?: string
  em?: string
  marketplaceBuyer?: string
  numOrders?: string
  optOut?: boolean
  pageType?: string
  proUser?: string
  segment?: string
  userID?: string
}
// TODO: Look at refactoring this to react query
const useUserStats = (
  userId: number | undefined,
  locale: string | undefined
) => {
  const [stats, setStats] = useState<UserStats | undefined>(undefined)
  const [statsFetched, setStatsFetched] = useState<boolean>(false)

  useEffect(() => {
    const fetchStats = async () => {
      if (!userId || !locale) {
        return
      }

      const response = await fetch(`/api/spoonflower/user-stats`, {
        method: 'GET',
        credentials: 'include',
      }).catch(() => {})
      const data = await response?.json()
      if (data?.data?.data_layer?.userID !== 0) {
        setStats(data)
      }
      setStatsFetched(true)
    }

    fetchStats()
  }, [userId, locale])

  return {
    stats,
    statsFetched,
  }
}

export default useUserStats
