import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { ComponentArticleCardFragment } from '../../utils'
import { ArticleCard } from '@oriuminc/ui'

export const ComponentArticleCard = ({
  image,
  eyebrow,
  title,
  content,
  href,
  textAlign,
}: ComponentArticleCardFragment) => {
  return (
    <ArticleCard
      image={{
        src: image?.url ?? '',
        alt: image?.title ?? '',
      }}
      eyebrow={{
        children: eyebrow ?? '',
      }}
      title={{
        children: title ?? '',
      }}
      description={{
        children: content?.json
          ? documentToReactComponents(content?.json)
          : undefined,
      }}
      href={href ?? ''}
      textAlign={textAlign as any}
    />
  )
}
