import { IntlConfig } from '@oriuminc/base'
import enCATranslations from './en-CA.json'
import enUSTranslations from './en-US.json'

//TODO - pull translations from the CMS (contentul / Amplience)

export const intlConfig: IntlConfig[] = [
  {
    code: 'en-US',
    title: 'English (en-US)',
    keys: {
      ...enUSTranslations,
    },
    translationKey: 'locale.title.en-US',
    currency: 'USD',
  },
  {
    code: 'en-CA',
    title: 'English (en-CA)',
    keys: {
      ...enUSTranslations,
      ...enCATranslations,
    },
    translationKey: 'locale.title.en-US',
    currency: 'CAD',
  },
  {
    code: 'en-AU',
    title: 'English (en-AU)',
    keys: {
      ...enUSTranslations,
      ...enCATranslations,
    },
    translationKey: 'locale.title.en-US',
    currency: 'AUD',
  },
  {
    code: 'en-GB',
    title: 'English (en-GB)',
    keys: {
      ...enUSTranslations,
      ...enCATranslations,
    },
    translationKey: 'locale.title.en-US',
    currency: 'GBP',
  },
  {
    code: 'en',
    title: 'English (en)',
    keys: {
      ...enUSTranslations,
      ...enCATranslations,
    },
    translationKey: 'locale.title.en-US',
    currency: 'EUR',
  },
]
