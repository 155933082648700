import { Preferences } from '@modules/spoonflower/utils/graphql/generated/graphql'
import { useMutation } from '@tanstack/react-query'
import axios from 'axios'

interface UpdateUserPreferencesResponse {
  userPreferences: Preferences
}

const updateUserPreferencesKey = ['updateUserPreferences']

function useUpdateUserPreferences() {
  return useMutation<Preferences, unknown, Preferences>(
    updateUserPreferencesKey,
    async (variables) => {
      const response = await axios.post<UpdateUserPreferencesResponse>(
        '/api/spoonflower/user-preferences',
        variables,
        {
          withCredentials: true,
        }
      )
      const {
        data: { userPreferences },
      } = response

      return userPreferences
    },
    {}
  )
}

export { useUpdateUserPreferences as default }
