import {
  CheckoutStateType,
  CartFragment,
  CartLineItemFragment,
} from '../../types'
import { GaCartFragment } from '../../types/gaTracking'

import { mergeDiscountsByName } from './helpers'
import type { Discount } from './helpers/types'

export const getCheckoutAddress = (
  ctAddress?: CartFragment['shippingAddress'] | CartFragment['billingAddress']
) => {
  return {
    additional_address_info: ctAddress?.additionalAddressInfo ?? '',
    city: ctAddress?.city ?? '',
    country: ctAddress?.country ?? '',
    first_name: ctAddress?.firstName ?? '',
    id: ctAddress?.id ?? 'new',
    last_name: ctAddress?.lastName ?? '',
    phone_number: ctAddress?.phone ?? '',
    postcode: ctAddress?.postalCode ?? '',
    state: ctAddress?.state ?? '',
    street_name: ctAddress?.streetName ?? '',
    street_number: ctAddress?.streetNumber ?? '',
  }
}

export const convertToCTAddress = (
  address: CheckoutStateType['shipping_address']
) => {
  return {
    additionalAddressInfo:
      address.additional_address_info !== ''
        ? address.additional_address_info
        : `${address.first_name} ${address.last_name}`,
    city: address.city,
    country: address.country,
    firstName: address.first_name,
    lastName: address.last_name,
    phone: address.phone_number,
    postalCode: address.postcode,
    state: address.state,
    streetName: address.street_name,
    streetNumber: address.street_number,
  }
}

interface GetCartTotalsOptions {
  shippingCostPreview?: number
}

export const getCartTotals = (
  cart: GaCartFragment,
  options?: GetCartTotalsOptions
) => {
  // works only with tax not included in price
  // commercetools -> Settings -> Project settings -> Taxes -> Included in price (unchecked)
  const shippingCostPreview = options?.shippingCostPreview || 0

  const cartHasTaxedCalculated = !!cart?.taxedPrice

  const itemsTotalNet =
    cart?.lineItems?.reduce((acc, item) => {
      const itemPrice = item.price?.value?.centAmount * item.quantity
      return acc + itemPrice
    }, 0) || 0

  const shipping =
    cart?.shippingInfo?.price.centAmount || shippingCostPreview || 0
  const discountedShipping =
    cart?.shippingInfo?.discountedPrice?.value.centAmount

  const itemsTotalTax = cart?.taxedPrice?.totalTax?.centAmount

  const itemsTotalDiscounts =
    cart?.lineItems?.reduce((acc, item) => {
      const itemDiscount =
        item.discountedPricePerQuantity[0]?.discountedPrice.includedDiscounts.reduce(
          (accum, discount) => {
            const discountAmount =
              discount.discountedAmount.centAmount * item.quantity || 0
            return accum + discountAmount
          },
          0
        ) || 0
      return acc + itemDiscount
    }, 0) || 0

  const total = cartHasTaxedCalculated
    ? cart.taxedPrice?.totalGross.centAmount
    : cart?.totalPrice?.centAmount || 0

  return {
    subtotal: itemsTotalNet,
    shipping,
    discountedShipping,
    tax: itemsTotalTax,
    discount: itemsTotalDiscounts,
    total: total + shippingCostPreview,
  }
}

export const getCartItemPrices = (item: CartLineItemFragment) => {
  // works only with tax not included in price
  // commercetools -> Settings -> Project settings -> Taxes -> Included in price (unchecked)
  const tax = item.taxedPrice?.totalTax?.centAmount

  const netPrice = item.price.value.centAmount
  const netSalePrice = item.price.discounted?.value.centAmount
  const total = item.totalPrice?.centAmount

  return {
    netPrice: netPrice,
    netSalePrice,
    tax,
    discount: netSalePrice ? netSalePrice - netPrice : 0,
    total,
  }
}

export const getCartDiscounts = (cart: CartFragment) => {
  // Line Items
  const discountsLineItems: Discount[] = []

  cart.lineItems?.forEach((lineItem) => {
    const { price, quantity } = lineItem
    const { discounted, value: priceValue } = price ?? {}
    const { discount, value: discountedValue } = discounted ?? {}
    const { name } = discount ?? {}

    if (name) {
      discountsLineItems.push({
        name,
        value:
          (priceValue?.centAmount - discountedValue?.centAmount) * quantity,
      })
    }
  })

  // Cart Discounts
  const cartDiscounts: Discount[] = []

  cart.lineItems?.forEach((lineItem) => {
    const { discountedPricePerQuantity } = lineItem

    discountedPricePerQuantity.forEach((lineItemDiscount) => {
      const { discountedPrice, quantity } = lineItemDiscount
      discountedPrice.includedDiscounts.forEach((includedDiscount) => {
        const { name } = includedDiscount.discount ?? {}
        const { centAmount } = includedDiscount.discountedAmount

        if (name) {
          cartDiscounts.push({
            name,
            value: centAmount * quantity,
          })
        }
      })
    })
  })

  // Shipping Discounts
  const shippingDiscounts: Discount[] = []

  const shippingDiscountedPrice = cart.shippingInfo?.discountedPrice

  if (shippingDiscountedPrice) {
    shippingDiscountedPrice.includedDiscounts.forEach((includedDiscount) => {
      const { name } = includedDiscount.discount ?? {}
      const { centAmount } = includedDiscount.discountedAmount

      if (name) {
        shippingDiscounts.push({
          name,
          value: centAmount,
          hide: !shippingDiscountedPrice.value.centAmount, // hides the shipping discount value if it's 100% (free shipping)
        })
      }
    })
  }

  const allDiscounts = mergeDiscountsByName([
    ...discountsLineItems,
    ...cartDiscounts,
    ...shippingDiscounts,
  ])

  return {
    lineItemDiscounts: allDiscounts,
  }
}
