import { megaMenuItemFragment } from '../fragments'

export const getMegaMenuQuery = /* GraphQL */ `
  query getMegaMenu($locale: String, $id: String!) {
    megaMenuCollection(limit: 1, where: { identifier: $id }) {
      items {
        identifier
        itemsCollection(limit: 10) {
          items {
            ...megaMenuItem
          }
        }
      }
    }
  }

  ${megaMenuItemFragment}
`
