import { default as chakraTheme } from '@chakra-ui/theme'
import breakpoints from './breakpoints'
import spacing from './spacing'

/**
 * Sizes documentation:
 * https://chakra-ui.com/docs/styled-system/theme#sizes
 *
 * This allows you to customize the global sizing of components you build for your project.
 * By default, these sizes value can be referenced by the `width`, `height`,
 * and `maxWidth`, `minWidth`, `maxHeight`, `minHeight` styles.
 */

const largeSizes = {
  max: 'max-content',
  min: 'min-content',
  full: '100%',
  '3xs': '14rem',
  '2xs': '16rem',
  xs: '20rem',
  sm: '24rem',
  md: '28rem',
  lg: '32rem',
  xl: '36rem',
  '2xl': '42rem',
  '3xl': '48rem',
  '4xl': '56rem',
  '5xl': '64rem',
  '6xl': '72rem',
  '7xl': '80rem',
  '8xl': '90rem',
  prose: '60ch',
}

const sizes = {
  ...chakraTheme.sizes,
  ...spacing,
  ...largeSizes,
  container: breakpoints,
}

export default sizes
