import axios, { AxiosRequestConfig } from 'axios'
import { createClient, ClientOptions } from 'urql'

import {
  CtAxiosError,
  CtLocalizedString,
  ProductFragment,
  ProductProjection,
  RawProductAttribute,
} from '../types'

export interface RoundDecimalsProps {
  num: number
  decimal?: 0 | 1 | 2 | 3 | 4
}
export interface GetSavePercentageProps {
  regular_amount: number
  sale_amount: number
  decimal?: RoundDecimalsProps['decimal']
}

export * from './checkout'

export const axiosCreateClient = (params: {
  host: string
  projectKey: string
  config?: AxiosRequestConfig
}) => {
  const apiUrl = getApiUrl(params.host, params.projectKey)
  return axios.create({
    baseURL: apiUrl,
    ...params.config,
  })
}

export const urqlCreateClient = (params: {
  host: string
  projectKey: string
  config?: Omit<ClientOptions, 'url'>
}) => {
  const url = getGraphqlUrl(params.host, params.projectKey)
  return createClient({ url, ...params.config })
}

export const getApiUrl = (host: string, projectKey: string) => {
  return `https://api.${host}/${projectKey}`
}

export const getGraphqlUrl = (host: string, projectKey: string) => {
  return `${getApiUrl(host, projectKey)}/graphql`
}

export const getAuthHeader = (token?: string) => {
  return token ? `Bearer ${token}` : ''
}

export const getLocalized = (
  locale: string,
  value?: CtLocalizedString
): string => {
  return value?.[locale] ?? ''
}

export const getError = (e: CtAxiosError) => {
  const [error] = e.response?.data.errors ?? []
  return {
    title: error?.message ?? 'Error',
    description: error?.message ?? 'An unexpected error has occurred.',
  }
}

export const getPriceAmount = (product: ProductProjection) => {
  const regularPrice = product.masterVariant.price
  const currentPrice = product.masterVariant.price?.discounted
  const regularPriceAmount = regularPrice?.value.centAmount
    ? regularPrice.value.centAmount / 100
    : 0
  const currentPriceAmount = currentPrice?.value.centAmount
    ? currentPrice.value.centAmount / 100
    : regularPriceAmount
  return { regularPriceAmount, currentPriceAmount }
}

/**
 * Returns percentage saved with rounded decimal places
 *
 * @param {number} regular_amount - e.g. `123.45`
 * @param {number} sale_amount - e.g. `123.45`
 * @param {number|0} decimal - e.g. `0 | 1 | 2 | 3 | 4 or undefined`
 * @returns Percentage saved with rounded decimal places
 */
export const getSavePercentage = ({
  regular_amount,
  sale_amount,
  decimal = 2,
}: GetSavePercentageProps) => {
  if (regular_amount <= 0 || regular_amount <= sale_amount) return 0
  if (sale_amount <= 0) return 100

  return roundDecimals({
    num: (100 * (regular_amount - sale_amount)) / regular_amount,
    decimal,
  })
}

/**
 * Returns number with rounded decimal places
 *
 * @param {number} num - e.g. `123.45`
 * @param {number|0} decimal - e.g. `0 | 1 | 2 | 3 | 4 or undefined`
 * @returns Number with rounded decimal places
 */
export const roundDecimals = ({ num, decimal = 2 }: RoundDecimalsProps) => {
  return Math.ceil((num + Number.EPSILON) * 10 ** decimal) / 10 ** decimal
}

export type ProductAttributesRaw = {
  name: string
  value: string
}

export const getVariants = ({
  product,
  channel,
}: {
  product?: ProductFragment
  channel?: string
}) => {
  if (!product) return {}

  const masterVariant = {
    variantId: product.masterVariant.id,
    variantOptions: product.masterVariant.attributesRaw.filter((attribute) =>
      Boolean(attribute.value?.key)
    ),
    availability: product.masterVariant.availability,
  }
  const variants = product.variants.map((variant) => {
    return {
      variantId: variant.id,
      variantOptions: variant.attributesRaw.filter((attribute) =>
        Boolean(attribute.value?.key)
      ),
      availability: variant.availability,
    }
  })
  const variantProductList = [masterVariant, ...variants]

  // get product -> variant map
  const flatProductVariantMap = variantProductList.map((variantProduct) => {
    const variantCombination =
      variantProduct.variantOptions.map((variant) => variant.value.label) ?? []

    const channelStock =
      variantProduct.availability?.channels.results.find(
        (item) => item.channel?.key === channel
      )?.availability.availableQuantity ??
      variantProduct.availability?.noChannel?.availableQuantity

    return {
      variantId: variantProduct.variantId,
      availability: variantProduct.availability,
      label: variantCombination.join(' '),
      outOfStock: Boolean(channelStock !== undefined && channelStock === 0),
      stock: channelStock,
    }
  })

  const variantNames = variantProductList
    .flatMap((variantProduct) =>
      variantProduct.variantOptions.map((option) => option.name)
    )
    .reduce((acc: string[], name: string) => {
      if (acc.indexOf(name) < 0) {
        acc.push(name)
      }
      return acc
    }, [])

  const variantSectionTitle = variantNames
    .map((variantName) => {
      const name = variantName.replace(/-/g, ' ')
      return name.charAt(0).toUpperCase() + name.slice(1)
    })
    .join('/')

  return { flatProductVariantMap, variantSectionTitle }
}

export const getVariantById = ({
  id,
  product,
}: {
  id: number
  product?: ProductFragment
}) => {
  if (!product) return null

  return (
    product.variants.find((variant) => variant.id === id) ??
    product.masterVariant
  )
}

export const getCurrencyFromLocale = (locale?: string) => {
  return locale && (locale === 'en-CA' || locale === 'fr-CA') ? 'CAD' : 'USD'
}

export const getVariantList = (
  attributesRaw?: Pick<RawProductAttribute, 'value' | 'name'>[]
) => {
  return attributesRaw
    ? attributesRaw
        .filter((attribute) => Boolean(attribute.value?.key))
        .map((variant) => {
          const formatedName = variant.name
            ?.split('-')
            .map((v) => v.charAt(0).toUpperCase() + v.slice(1))
            .join(' ')
          return { name: formatedName, value: variant.value.label }
        })
    : undefined
}
