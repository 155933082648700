import { useEffect } from 'react'
import { PATHS } from 'constants/paths'
import { useRouter } from 'next/router'

export const useIsBrowserRedirect = () => {
  const router = useRouter()

  const handlePopstate = () => {
    // The user navigated back using the browser's back button
    const currentRoute = window.location.pathname + window.location.hash

    if (currentRoute === PATHS.BILLING || currentRoute === PATHS.REVIEW) {
      router.push(PATHS.CART)
    }
  }

  useEffect(() => {
    window.addEventListener('popstate', handlePopstate)

    return () => {
      window.removeEventListener('popstate', handlePopstate)
    }
  }, [])
}
