import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { ComponentBannerTextOnlyFragment } from '../../utils'
import { BannerTextOnly } from '../../../ui'

export const ComponentBannerTextOnly = ({
  eyebrow,
  title,
  content,
  ctaAlphaHref,
  ctaAlphaLabel,
  centered,
}: ComponentBannerTextOnlyFragment) => {
  return (
    <BannerTextOnly
      centered={centered ?? undefined}
      text={{
        eyebrow: {
          children: eyebrow,
        },
        title: {
          children: title,
        },
        body: {
          children: content?.json
            ? documentToReactComponents(content.json)
            : undefined,
        },
        ctaButtonPrimary: {
          children: ctaAlphaLabel,
          href: ctaAlphaHref ?? '',
        },
      }}
    />
  )
}
