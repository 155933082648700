import {
  addressFragment,
  baseMoneyFragment,
  cartFragment,
  cartLineItemFragment,
  customerFragment,
  moneyFragment,
  taxedItemPriceFragment,
  taxedPriceFragment,
} from '../fragments'

export const getActiveCartQuery = /* GraphQL */ `
  query getActiveCart($locale: Locale = "en-US") {
    me {
      activeCart {
        ...cart
      }
    }
  }
  ${cartFragment}
  ${customerFragment}
  ${cartLineItemFragment}
  ${moneyFragment}
  ${taxedPriceFragment}
  ${addressFragment}
  ${baseMoneyFragment}
  ${taxedItemPriceFragment}
`
