export const moneyFragment = /* GraphQL */ `
  fragment money on Money {
    type
    currencyCode
    centAmount
    fractionDigits
  }
`

export const baseMoneyFragment = /* GraphQL */ `
  fragment baseMoney on BaseMoney {
    type
    currencyCode
    centAmount
    fractionDigits
  }
`

export const productPriceFragment = /* GraphQL */ `
  fragment productPrice on ProductPriceSearch {
    value {
      ...money
    }
    discounted {
      value {
        ...money
      }
    }
  }
  ${moneyFragment}
`

export const taxedPriceFragment = /* GraphQL */ `
  fragment taxedPrice on TaxedPrice {
    totalNet {
      ...money
    }
    totalGross {
      ...money
    }
    taxPortions {
      rate
      amount {
        ...money
      }
      name
    }
    totalTax {
      ...money
    }
  }
`

export const taxedItemPriceFragment = /* GraphQL */ `
  fragment taxedItemPrice on TaxedItemPrice {
    totalNet {
      ...money
    }
    totalGross {
      ...money
    }
    totalTax {
      ...money
    }
  }
`
