export const siteConfigFragment = /* GraphQL */ `
  fragment siteConfig on SiteConfig {
    order(locale: $locale)
    name(locale: $locale)
    key(locale: $locale)
    url(locale: $locale)
    displayMultiBrandBanner(locale: $locale)
    brandLogo(locale: $locale) {
      url(locale: $locale)
      width
      height
    }
    brandLogoSmall(locale: $locale) {
      url(locale: $locale)
      width
      height
    }
    shortcutIcon(locale: $locale) {
      url(locale: $locale)
    }
    siblingSitesCollection(limit: 10) {
      items {
        order(locale: $locale)
        name(locale: $locale)
        key(locale: $locale)
        url(locale: $locale)
        displayMultiBrandBanner(locale: $locale)
        brandLogo {
          url
          width
          height
        }
        brandLogoSmall {
          url
          width
          height
        }
      }
    }
  }
`
