export const addressFragment = /* GraphQL */ `
  fragment address on Address {
    id
    email
    salutation
    title
    firstName
    lastName
    company
    country
    city
    streetName
    streetNumber
    state
    region
    apartment
    building
    postalCode
    pOBox
    phone
    mobile
    fax
    additionalAddressInfo
    additionalStreetInfo
  }
`
