import { mode, Styles } from '@chakra-ui/theme-tools'

const styles: Styles = {
  // global: props => ({
  //   body: {
  //     fontFamily: 'body',
  //     color: mode('gray.800', 'whiteAlpha.900')(props),
  //     bg: mode('red', 'green')(props),
  //   },
  // }),
}

export default styles
