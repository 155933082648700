import { Grid } from '@chakra-ui/react'
import { ComponentGridFragment } from '../../utils'
import { ComponentArticleCard } from '../ComponentArticleCard'
import { ComponentCoverCard } from '../ComponentCoverCard'
import { ComponentTextCard } from '../ComponentTextCard'

export const ComponentGrid = ({
  contentCollection,
  columns,
  gridGap,
}: ComponentGridFragment) => {
  if (!contentCollection?.items.length) {
    return null
  }

  const _columns = columns ?? contentCollection?.items.length

  return (
    <Grid
      gridGap={gridGap || 0}
      alignItems="stretch"
      templateColumns={{
        base: `repeat(${_columns > 2 ? 2 : _columns}, 1fr)`,
        md: `repeat(${_columns > 4 ? 4 : _columns}, 1fr)`,
        lg: `repeat(${_columns > 6 ? 6 : _columns}, 1fr)`,
      }}
    >
      {contentCollection?.items.filter(Boolean).map((item, i) => {
        switch (item?.__typename) {
          case 'ComponentArticleCard': {
            return <ComponentArticleCard key={item.sys.id} {...item} />
          }

          case 'ComponentCoverCard': {
            return <ComponentCoverCard key={item.sys.id} {...item} />
          }

          case 'ComponentTextCard': {
            return <ComponentTextCard key={item.sys.id} {...item} />
          }

          default: {
            return <div key={i} />
          }
        }
      })}
    </Grid>
  )
}
