import { customerFragment } from '../fragments'

export const updateMyCustomerMutation = /* GraphQL */ `
  mutation updateMyCustomer(
    $actions: [MyCustomerUpdateAction!]!
    $storeKey: KeyReferenceInput
    $version: Long!
  ) {
    updateMyCustomer(
      actions: $actions
      storeKey: $storeKey
      version: $version
    ) {
      ...customer
    }
  }
  ${customerFragment}
`
