import textStyles from '../textStyles'

/**
 * Text component documentation:
 * https://chakra-ui.com/docs/components/text
 */

const styles = {
  baseStyle: {
    fontWeight: 'normal',
    letterSpacing: 'normal',
    lineHeight: 'short',
  },
  sizes: {
    xs: {
      fontSize: 'xs',
    },
    sm: {
      fontSize: 'sm',
    },
    md: {
      fontSize: 'md',
    },
    lg: {
      fontSize: 'lg',
    },
    xl: {
      fontSize: 'xl',
    },
    '2xl': {
      fontSize: '2xl',
    },
    '3xl': {
      fontSize: '3xl',
    },
    '4xl': {
      fontSize: '4xl',
    },
    '5xl': {
      fontSize: '5xl',
    },
    '6xl': {
      fontSize: '6xl',
    },
    '7xl': {
      fontSize: '7xl',
    },
    '8xl': {
      fontSize: '8xl',
    },
    '9xl': {
      fontSize: '9xl',
    },
  },
  variants: {
    p: {
      /**
       * What we should be doing here is "textStyle: 'p'", however:
       * https://github.com/chakra-ui/chakra-ui/issues/3884
       */
      ...textStyles.p,
    },
  },
}

export default styles
