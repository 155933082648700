import { GetStaticPropsContext } from 'next'
import { staticContenfulPage } from './utils/staticPage'
import { ContentfulPage } from './pages'
import { Footer, MegaDrawer, MegaMenu } from './components'
import { CmsInterface } from '../cms/types'
import { MEGA_MENU_ID } from './constants'
import { prefetchCommonComponents } from './utils'
import { contentfulSiteConfigFetchService } from './hooks'

export const contentfulInterface: CmsInterface = {
  // prefetchCommonComponents: prefetchCommonComponents,
  genericContentPage: {
    getStaticPaths: () => {
      return { paths: [], fallback: 'blocking' }
    },
    getStaticProps: async (context: GetStaticPropsContext) => {
      const { getServerSidePropsValue } = await staticContenfulPage({
        context,
      })
      const serverSideProps = getServerSidePropsValue()
      return {
        ...serverSideProps,
        props: {
          ...serverSideProps.props,
          isPreview: !!context.preview,
        },
      }
    },
    Page: ({
      props,
      ProductsConnector,
    }: {
      props: any
      ProductsConnector: any
    }) => {
      return <ContentfulPage {...props} ProductsConnector={ProductsConnector} />
    },
  },
  // MegaMenu: <MegaMenu megaMenuId={MEGA_MENU_ID.MAIN_NAV} />,
  // MegaDrawer: <MegaDrawer megaMenuId={MEGA_MENU_ID.MAIN_NAV} />,
  // Footer: <Footer megaMenuId={MEGA_MENU_ID.FOOTER} />,

  // getSiteConfig: contentfulSiteConfigFetchService,
}
