import {
  contentfulMegaMenuFetchService,
  contentfulSiteConfigFetchService,
  getContentfulMegaMenuKey,
} from '../hooks'
import { BRAND_NAME, MEGA_MENU_ID } from '../constants'
import { getSiteConfigKey } from '@oriuminc/base'
import { PrefetchCommonComponentsParams } from '../../cms/types'

export const prefetchCommonComponents = async ({
  queryClient,
  locale,
}: PrefetchCommonComponentsParams) => {
  await Promise.all([
    queryClient.prefetchQuery(
      getContentfulMegaMenuKey({ locale, id: MEGA_MENU_ID.MAIN_NAV }),
      async () =>
        await contentfulMegaMenuFetchService({
          locale,
          id: MEGA_MENU_ID.MAIN_NAV,
        })
    ),
    queryClient.prefetchQuery(
      getContentfulMegaMenuKey({ locale, id: MEGA_MENU_ID.FOOTER }),
      async () =>
        await contentfulMegaMenuFetchService({
          locale,
          id: MEGA_MENU_ID.FOOTER,
        })
    ),
    queryClient.prefetchQuery(
      getSiteConfigKey(locale),
      async () =>
        await contentfulSiteConfigFetchService({
          key: BRAND_NAME,
          locale,
        })
    ),
  ])
}
