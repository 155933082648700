import {
  addressFragment,
  baseMoneyFragment,
  cartFragment,
  cartLineItemFragment,
  customerFragment,
  moneyFragment,
  taxedItemPriceFragment,
  taxedPriceFragment,
} from '../fragments'

export const createMyCartMutation = /* GraphQL */ `
  mutation createMyCart($draft: MyCartDraft!, $locale: Locale!) {
    createMyCart(draft: $draft) {
      ...cart
    }
  }
  ${cartFragment}
  ${customerFragment}
  ${cartLineItemFragment}
  ${moneyFragment}
  ${taxedPriceFragment}
  ${addressFragment}
  ${baseMoneyFragment}
  ${taxedItemPriceFragment}
`
