import { default as chakraTheme } from '@chakra-ui/theme'

/**
 * Breakpoints documentation:
 * https://chakra-ui.com/docs/styled-system/theme#breakpoints
 */

const breakpoints = {
  ...chakraTheme.breakpoints,

  xs: '20rem', // 320px
  sm: '26.75rem', // 428px
  md: '48rem', // 768px
  lg: '64rem', // 1024px
  xl: '80rem', // 1280px
  '2xl': '94.5rem', // 1512px
  '3xl': '108rem', // 1728px

  /* Did not want to modify the breakpoints above. The below were created and added to the Header component & Cart Page */
  'sp-sm': '27.75em', // 444px
  'sp-md': '30em', // 480px
  'sp-lg': '52.125em', // 834px
  'sp-xl': '79.9em', // 1279px
  'sp-2xl': '94.5em', // 1512px
} as const

export default breakpoints
