import { productFragement } from '../fragments'

export const getProductsQuery = /* GraphQL */ `
  query getProducts(
    $currency: Currency!
    $locale: Locale!
    $queryFilters: [SearchFilterInput!]
    $limit: Int
    $customerGroupId: String
  ) {
    productProjectionSearch(
      queryFilters: $queryFilters
      limit: $limit #   queryFilters: $queryFilters
    ) {
      offset
      count
      total
      results {
        ...product
      }
    }
  }
  ${productFragement}
`
