import * as React from 'react'
import { useRouter } from 'next/router'
import { GetSiteConfigService, IntlConfig } from '../../types'
import { UseDisclosureReturn, useDisclosure } from '@chakra-ui/react'
import { getActiveLocaleCurrency } from '../../utils'

export interface QueryParams {
  [key: string]: string | number | boolean
}

export interface ComposableContextInterface {
  locale: string
  currency: string
  intl: IntlConfig[]
  setLocale: (locale: string) => void
  cartDrawer: UseDisclosureReturn
  megaDrawer: UseDisclosureReturn
  accountDrawer: UseDisclosureReturn
  path: {
    getAccountDashboard: (params?: { page?: string }) => string
    getAccountForgot: () => string
    getAccountReset: () => string
    getAccountLogin: (queryParams?: QueryParams) => string
    getAccountRegister: () => string
    getCart: () => string
    getCheckout: () => string
    getCheckoutSuccess: (params: { order?: string }) => string
    getHome: () => string
    getPDP: (params: { slug: string }) => string
    getPLP: (params: { slug: string }) => string
    getSearch: (params: { query: string }) => string
  }
  getSiteConfig?: GetSiteConfigService
  appKey: string
}

const ComposableContext = React.createContext<
  ComposableContextInterface | undefined
>(undefined)

export type ComposableProviderProps = Partial<ComposableContextInterface> & {
  intl: ComposableContextInterface['intl']
  children: JSX.Element
}

export const FALLBACK_LOCALE = 'en-US'
export const FALLBACK_CURRENCY = 'USD'

export const queryParamsToString = (params?: QueryParams) => {
  if (!params) return ''

  return Object.keys(params)
    .map((key) => key + '=' + params[key])
    .join('&')
}

export const ComposableProvider = ({
  children,
  intl,
  getSiteConfig,
  appKey,
}: ComposableProviderProps) => {
  const router = useRouter()
  const currentLocale =
    router?.locale || router?.defaultLocale || FALLBACK_LOCALE
  const cartDrawer = useDisclosure()
  const megaDrawer = useDisclosure()
  const accountDrawer = useDisclosure()

  const currency =
    getActiveLocaleCurrency({
      activeLocale: currentLocale,
      intlConfig: intl,
    }) || FALLBACK_CURRENCY

  const path = React.useRef<ComposableContextInterface['path']>({
    getAccountDashboard: (params?) =>
      `/account/dashboard/${params?.page ? params.page : ''}`,
    getAccountForgot: () => '/account/forgot',
    getAccountReset: () => '/account/reset',
    getAccountLogin: (params?) =>
      `/account/login${params ? '?' + queryParamsToString(params) : ''}`,
    getAccountRegister: () => '/account/register',
    getCart: () => '/cart',
    getCheckout: () => '/checkout',
    getCheckoutSuccess: (params) => `/checkout/success?order=${params.order}`,
    getHome: () => '/',
    getPDP: (params) => `/product/${params.slug}`,
    getPLP: (params) => `/category/${params.slug}`,
    getSearch: (params) => `/search?query=${params.query}`,
  })

  const setLocaleHandler = React.useCallback(
    (locale: string) => {
      const { pathname, asPath, query } = router
      router.push({ pathname, query }, asPath, { locale })
    },
    [router]
  )

  return (
    <ComposableContext.Provider
      value={{
        path: path.current,
        locale: currentLocale,
        cartDrawer,
        megaDrawer,
        accountDrawer,
        intl,
        setLocale: setLocaleHandler,
        currency,
        getSiteConfig,
        appKey: appKey || '',
      }}
    >
      {children}
    </ComposableContext.Provider>
  )
}

export const useComposable = () => {
  const context = React.useContext(ComposableContext)
  if (context === undefined) {
    throw new Error('useComposable must be used within a ComposableProvider')
  }
  return context
}
