import * as React from 'react'
import { DefaultSeo } from '../DefaultSeo'

interface MainProps {
  children: React.ReactElement | React.ReactElement[]
}

export const Main = ({ children }: MainProps) => {
  return (
    <>
      <DefaultSeo />
      {children}
    </>
  )
}
