import { useMediaQuery } from '@chakra-ui/react'
import { useRouter } from 'next/router'
import { CheckoutStepLink } from '../../CheckoutLayout/CheckoutStepLink'
import { useHeaderOptions } from '../hooks/useHeaderOptions'
import { PATHS } from 'constants/paths'
import { useIntl } from 'react-intl'
import { useCart } from '@modules/commercetools'
import { HeaderLayout } from '../HeaderLayout'

export const CartHeader = () => {
  const intl = useIntl()
  const router = useRouter()

  const initialOptionsState = useHeaderOptions()
  const { cart } = useCart({})

  const handleRedirect = () => router.push(PATHS.CHECKOUT)

  const [isSmallerThanReview] = useMediaQuery('(max-width: 720px)')
  const [isSmallerThanPayment] = useMediaQuery('(max-width: 620px)')
  const [isSmallerThanShipping] = useMediaQuery('(max-width: 520px)')

  return (
    <HeaderLayout>
      {!isSmallerThanShipping && (
        <CheckoutStepLink
          key={''}
          title={intl.formatMessage({
            id: 'checkout.header.options.cart',
          })}
          isAllowed={true}
          active={true}
          cartLinkBaseColor="primary.text"
        />
      )}

      {initialOptionsState.map((navStep) => {
        const isAllowed = navStep.id === 1 && !cart.isEmpty

        const navItemBreakpoints = {
          1: isSmallerThanShipping,
          2: isSmallerThanPayment,
          3: isSmallerThanReview,
        } as { [key: number]: boolean }

        return (
          <CheckoutStepLink
            key={navStep.id}
            active={false}
            title={`${navStep.id}. ${navStep.name}`}
            onClick={handleRedirect}
            isAllowed={isAllowed}
            display={!navItemBreakpoints[navStep.id] ? 'flex' : 'none'}
          />
        )
      })}
    </HeaderLayout>
  )
}
