import { useClientSideCookie } from '@modules/cart/hooks/useClientSideCookie'
import {
  AlpCurrency,
  Country,
} from '@modules/spoonflower/utils/graphql/generated/graphql'
import { useComposable } from '@modules/oriuminc/base'
import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import { defaults } from './useUserPreferences'

interface CountryAndCurrency {
  currentCurrency: string
  country: Country
  currency: AlpCurrency
}

// TODO: The keys should be based off AlpCurrency
const currencyLocales = {
  USD: 'en-US',
  AUD: 'en-AU',
  CAD: 'en-CA',
  GBP: 'en-GB',
  EUR: 'en',
}
const useChangeCartCurrencyKey = ['useChangeCartCurrency']

/**
 * Commercetools requires a locale string that's different than the one we use in Spoonflower.
 * To get it, we map the User's preferred currency to the Commercetools locale.
 */
const getCommercetoolsLocaleFromCurrency = (currency: AlpCurrency) => {
  return currencyLocales[currency] ?? defaults.locale
}

const useChangeCartCurrency = () => {
  const token = useClientSideCookie()
  const { locale, setLocale } = useComposable()

  return useMutation<void, unknown, CountryAndCurrency>(
    useChangeCartCurrencyKey,
    async ({ currentCurrency, country, currency }) => {
      const data = {
        currentCurrency,
        token,
        cartOptions: {
          currency,
          taxMode: 'ExternalAmount',
          shippingAddress: {
            country,
          },
        },
      }
      const options = {
        withCredentials: true,
      }
      const url = '/api/commercetools/change-cart-currency'

      return axios.post(url, data, options)
    },
    {
      onSuccess: (data, variables, context) => {
        const { currency } = variables
        const newLocale = getCommercetoolsLocaleFromCurrency(currency)

        if (locale !== newLocale) {
          setLocale(newLocale)
        }
      },
    }
  )
}

export { getCommercetoolsLocaleFromCurrency, useChangeCartCurrency as default }
