import { useRouter } from 'next/router'
import { NextSeo } from 'next-seo'
import { NoMatchPage } from '../../../app/pages/NoMatchPage'
import { useContentfulPage } from '../../hooks'
import { UiContainer } from '@oriuminc/ui'
import { HOME_PAGE } from '../../constants'
import {
  ComponentBannerFull,
  ComponentBannerSplit,
  ComponentBannerTextOnly,
  ComponentCoverCard,
  ComponentGrid,
} from '../../components'
import React from 'react'

export interface ContentfulPageProps {
  ProductsConnector?: typeof React.Component
}

export const ContentfulPage = ({ ProductsConnector }: ContentfulPageProps) => {
  const router = useRouter()
  const slug = `${router.query.slug?.toString() || HOME_PAGE}`
  const { data, isNoMatch } = useContentfulPage(slug)

  if (isNoMatch) {
    return <NoMatchPage />
  }

  return (
    <>
      <NextSeo
        title={data?.metaTitle || undefined}
        description={data?.metaTitle || undefined}
        additionalMetaTags={
          data?.metaKeywords
            ? [
                {
                  property: 'keywords',
                  content: `${data.metaKeywords}`,
                },
              ]
            : undefined
        }
      />

      {data?.contentCollection?.items?.map((item) => {
        switch (item?.__typename) {
          case 'ComponentBannerFull': {
            return (
              <UiContainer
                key={item.sys?.id}
                size={item.containerSize}
                marginBottom={item.containerMarginBottom}
                marginTop={item.containerMarginTop}
              >
                <ComponentBannerFull {...item} />
              </UiContainer>
            )
          }
          case 'ComponentBannerSplit': {
            return (
              <UiContainer
                key={item.sys?.id}
                size={item.containerSize}
                marginBottom={item.containerMarginBottom}
                marginTop={item.containerMarginTop}
              >
                <ComponentBannerSplit {...item} />
              </UiContainer>
            )
          }
          case 'ComponentBannerTextOnly': {
            return (
              <UiContainer
                key={item.sys?.id}
                size={item.containerSize}
                marginBottom={item.containerMarginBottom}
                marginTop={item.containerMarginTop}
              >
                <ComponentBannerTextOnly {...item} />
              </UiContainer>
            )
          }
          case 'ComponentCoverCard': {
            return (
              <UiContainer
                key={item.sys?.id}
                size={item.containerSize}
                marginBottom={item.containerMarginBottom}
                marginTop={item.containerMarginTop}
              >
                <ComponentCoverCard {...item} />
              </UiContainer>
            )
          }
          case 'ComponentGrid': {
            return (
              <UiContainer
                key={item.sys?.id}
                size={item.containerSize}
                marginBottom={item.containerMarginBottom}
                marginTop={item.containerMarginTop}
              >
                <ComponentGrid {...item} />
              </UiContainer>
            )
          }
          case 'ComponentEpConnector': {
            // only EP should load this...
            if (!process.env.NEXT_PUBLIC_ELASTIC_PATH_HOST) {
              return null
            }
            return (
              <UiContainer
                key={item.sys?.id}
                size={item.containerSize}
                marginBottom={item.containerMarginBottom}
                marginTop={item.containerMarginTop}
              >
                {ProductsConnector && <ProductsConnector {...item} />}
              </UiContainer>
            )
          }
          case 'ComponentCtConnector': {
            // only CT should load this...
            if (!process.env.NEXT_PUBLIC_COMMERCETOOLS_HOST) {
              return null
            }
            return (
              <UiContainer
                key={item.sys?.id}
                size={item.containerSize}
                marginBottom={item.containerMarginBottom}
                marginTop={item.containerMarginTop}
              >
                {ProductsConnector && (
                  <ProductsConnector
                    {...item}
                    cmsProductsList={item.productList}
                    productListType="sku"
                  />
                )}
              </UiContainer>
            )
          }
          default: {
            return null
          }
        }
      })}
    </>
  )
}
