import * as React from 'react'
import { AppProps } from 'next/app'
import {
  QueryClientProvider,
  QueryClient,
  Hydrate,
  QueryClientConfig,
} from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { ChakraProvider } from '@chakra-ui/react'
import { IntlConfig } from '../../types'
import {
  ComposableContextInterface,
  ComposableProvider,
} from '../ComposableProvider'
import { IntlProvider } from '../IntlProvider'
import { Main } from '../Main'
import { GoogleAnalyticsBase } from '../GoogleAnalytics'
import { GoogleTagManagerBase } from '../GoogleTagManager'

import 'focus-visible/dist/focus-visible' // Disabling border for non-keyboard interactions

export type ComposableProps = Partial<Pick<AppProps, 'pageProps'>> & {
  children: React.ReactElement | React.ReactElement[]
  theme: any
  locale?: string
  intl: IntlConfig[]
  googleAnalyticsId?: string
  googleTagManagerId?: string
  getSiteConfig?: ComposableContextInterface['getSiteConfig']
  appKey?: string
}

export const Composable = ({
  pageProps,
  theme,
  intl,
  locale,
  children,
  googleAnalyticsId,
  googleTagManagerId,
  getSiteConfig,
  appKey,
}: ComposableProps) => {
  const queryClientRef = React.useRef<QueryClient>()

  if (queryClientRef.current == null) {
    queryClientRef.current = new QueryClient(queryConfigDefault)
  }

  return (
    <ComposableProvider
      intl={intl}
      locale={locale}
      getSiteConfig={getSiteConfig}
      appKey={appKey}
    >
      <IntlProvider>
        <QueryClientProvider client={queryClientRef.current}>
          {/* @ts-ignore */}
          <Hydrate state={pageProps?.dehydratedState}>
            <ChakraProvider theme={theme}>
              <GoogleTagManagerBase googleTagManagerId={googleTagManagerId} />
              <GoogleAnalyticsBase googleAnalyticsId={googleAnalyticsId} />
              <Main>{children}</Main>
            </ChakraProvider>
            <ReactQueryDevtools initialIsOpen={false} />
          </Hydrate>
        </QueryClientProvider>
      </IntlProvider>
    </ComposableProvider>
  )
}

const queryConfigDefault: QueryClientConfig = {
  defaultOptions: {
    queries: {
      staleTime: 2.5 * 60 * 1000,
      cacheTime: 5 * 60 * 1000,
      refetchOnWindowFocus: false,
      refetchOnMount: 'always',
    },
  },
}
