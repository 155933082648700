export enum ProductKey {
  ColorMap = 'color-map',
  FabricSampler = 'fabric-sampler',
  FillAYard = 'fill-a-yard',
  PrintedFabric = 'printed-fabric',
  Subscriptions = 'subscriptions',
  UnprintedFabric = 'unprinted-fabric',
  Wallpaper = 'wallpaper',

  // Home Goods
  Curtain = 'curtain',
  DuvetCover = 'duvet-cover',
  Napkin = 'napkin',
  PillowShams = 'pillow-sham',
  Placemat = 'placemat',
  SheetSet = 'sheet-set',
  TableRunner = 'table-runner',
  Tablecloth = 'tablecloth',
  TeaTowel = 'tea-towel',
  ThrowBlanket = 'throw-blanket',
  ThrowPillow = 'throw-pillow',
  WallHanger = 'wall-hanger',
  WallHanging = 'wall-hanging',
}
