import NextLink from 'next/link'
import { Button, useBreakpointValue } from '@chakra-ui/react'

interface BackToShoppingButtonProps {
  redirectUrl: string
  buttonText: string
}

export const BackToShoppingButton = ({
  redirectUrl,
  buttonText,
}: BackToShoppingButtonProps) => {
  const isMobile = useBreakpointValue({ base: true, md: false }) // breakpoint begins at 768px

  return isMobile ? (
    <NextLink href={redirectUrl} passHref>
      <Button
        height={5}
        color="primary.text"
        alignSelf="center"
        variant="outline"
        fontSize="sm"
        paddingBlock="10px"
        paddingInline="8px"
        position="absolute"
        top="20px"
        right="20px"
      >
        {buttonText}
      </Button>
    </NextLink>
  ) : (
    <NextLink href={redirectUrl} passHref>
      <Button
        height={5}
        color="primary.text"
        alignSelf="center"
        variant="outline"
        fontSize="md"
        paddingBlock="10px"
        paddingInline="8px"
        marginLeft="auto"
        marginRight="40px"
      >
        {buttonText}
      </Button>
    </NextLink>
  )
}
