const dividerStyles = {
  // Styles for the base style
  baseStyle: {
    borderColor: 'gray.300',
  },
  variants: {
    solid: {
      borderColor: 'gray.300',
    },
    dashed: {
      borderColor: 'gray.300',
    },
  },
}

export default dividerStyles
