import { VariantAttribute } from '@modules/cart/components/CartItemList/hooks/useLineItemAttributes'
import { useClientSideCookie } from '@modules/cart/hooks/useClientSideCookie'
import { useLocalStorage, writeStorage } from '@oriuminc/base'
import { useComposable } from '@modules/oriuminc/base'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import axios, { AxiosError, AxiosResponse } from 'axios'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useCommercetools } from '../../components'
import { CART_FIXED, LOCAL_STORAGE_CT_CART_UPDATED } from '../../constants'
import {
  gaTrackAddToCart,
  gaTrackRemoveFromCart,
  gaTrackUpdateCart,
} from '../../integrations'
import {
  cartAddressUpdateService,
  cartCreateService,
  cartFetchService,
  cartItemAddService,
  cartItemDeleteService,
  cartItemUpdateService,
  cartUpdateService,
} from '../../services'
import {
  AddressInput,
  CartLineItemFragment,
  CheckoutStateShippingAddress,
  CtAxiosError,
  CustomFieldsDraft,
  Maybe,
  MyCartUpdateAction,
  Reference,
  UpdateMyCartMutation,
  UpdateMyCartMutationVariables,
} from '../../types'
import { useUser } from '../useUser'
import { Cart } from '@oriuminc/commercetools'
import { useToast } from '@modules/ui'
import { useIntl } from 'react-intl'
import { Link } from '@modules/app'
import { Wrap, WrapItem } from '@chakra-ui/react'

const SAMPLE_CUSTOM_FIELDS = {
  type: {
    typeId: 'type',
    key: 'lineItemCustomDesignType',
  },
  fields: [
    { name: 'artistName', value: JSON.stringify('fineapple_pair') },
    {
      name: 'artistID',
      value: JSON.stringify('58d280b6-63d3-4d14-8e4b-8fb54c15fa88'),
    },
    { name: 'designDescription', value: JSON.stringify('BOHO Tropical') },
    {
      name: 'designID',
      value: JSON.stringify('0db098b8-83b3-42f2-953b-a2a32f4efe32'),
    },
    {
      name: 'designImageURL',
      value: JSON.stringify(
        'https://garden.spoonflower.com/c/8498488/i/s/HvNNrAke6MwArelxC-crgmoYDswBhk14J-TVh3PYYLjV-DSHbmUVLns/8498488.jpg'
      ),
    },
  ],
}

const setCartUpdatedAt = (timestamp: number) => {
  writeStorage(LOCAL_STORAGE_CT_CART_UPDATED, timestamp)
}

interface UseCartOptions {
  onCartItemAddError?: (err: CtAxiosError) => void
  onCartItemUpdateError?: (err: CtAxiosError) => void
  onCartItemDeleteError?: (err: CtAxiosError) => void
  onCartItemAddSuccess?: (cart: UpdateMyCartMutation['updateMyCart']) => void
  onCartAddressUpdateError?: (err: CtAxiosError) => void
  onCartAddPaymentError?: (err: CtAxiosError) => void
  onCartUpdateError?: (err: CtAxiosError) => void
}

export const useCart = (options?: UseCartOptions) => {
  const accessToken = useClientSideCookie()
  const queryClient = useQueryClient()
  const { currency, locale } = useComposable()
  const intl = useIntl()
  const { customer } = useUser()
  const { graphqlClient } = useCommercetools()
  const [creatingCart, setCreatingCart] = useState(false)
  const [updatedAt] = useLocalStorage(LOCAL_STORAGE_CT_CART_UPDATED, Date.now())
  const optionsRef = useRef<undefined | UseCartOptions>(options)
  const toast = useToast()
  optionsRef.current = options
  const useCtCartKey = ['useCtCartKey', currency]

  /**
   * Fetch Cart
   */
  const {
    data: cart,
    isFetched,
    refetch: fetchCart,
    status,
  } = useQuery(
    useCtCartKey,
    async () => {
      const response = await cartFetchService({
        currency: currency,
        graphqlClient,
        params: {
          variables: {
            locale,
          },
        },
        token: accessToken?.access_token ?? '',
      })
      return response
    },
    {
      retry: false,
      enabled: accessToken?.access_token !== '' && !!accessToken?.access_token,
      keepPreviousData: true,
    }
  )

  const cartId = cart?.id ?? null

  useQuery<{ msg: string; wasCurrencyChanged: boolean }>(
    ['useFixCartKey'],
    async () => {
      return axios
        .post('/api/commercetools/fix-cart', {
          cartId,
        })
        .then((res) => {
          if (res.data?.msg === CART_FIXED) {
            setCartUpdatedAt(Date.now()) // refetches the cart as a side effect only if fixes were applied
          }
          if (res.data?.wasCurrencyChanged) {
            toast({
              description: (
                <Wrap spacingY="0">
                  <WrapItem>
                    {intl.formatMessage({
                      id: 'cart.fixedCart.allPricesHaveBeenUpdated',
                    })}
                  </WrapItem>
                  <WrapItem>
                    <Link
                      href="https://support.spoonflower.com/hc/en-us/articles/215118066-Payment-Methods"
                      isExternal
                    >
                      {intl.formatMessage({ id: 'cart.fixedCart.learnMore' })}
                    </Link>
                  </WrapItem>
                </Wrap>
              ),
              duration: null,
              status: 'info',
            })
          }
          return res.data
        })
    },
    {
      retry: false,
      enabled:
        accessToken?.access_token !== '' &&
        !!accessToken?.access_token &&
        !!cartId,
      keepPreviousData: true,
    }
  )

  /**
   * Create Cart
   */
  const cartCreate = useCallback(async () => {
    setCreatingCart(true)
    const response = await cartCreateService({
      // TODO: handle error
      graphqlClient,
      token: accessToken?.access_token ?? '',
      params: {
        locale,
        currency,
        customerEmail: customer?.email,
      },
    })
    const id = response?.id ?? ''
    setCreatingCart(false)
    return id
  }, [
    queryClient,
    graphqlClient,
    accessToken?.access_token ?? '',
    currency,
    customer,
  ])

  /**
   * Add Cart Item
   */
  const cartItemAdd = useMutation(
    ['cartItemAdd'],
    async (variables: {
      cartId: string
      productId: string
      variantId?: number
      quantity?: number
      custom?: Maybe<CustomFieldsDraft>
    }) => {
      const version = (await fetchCart()).data?.version

      const params = {
        cartId: variables.cartId,
        version,
        productId: variables.productId,
        variantId: variables.variantId,
        quantity: variables.quantity,
        custom: variables.custom,
        locale,
      }

      const response = await cartItemAddService({
        graphqlClient,
        token: accessToken?.access_token ?? '',
        params,
      })

      queryClient.setQueryData(useCtCartKey, () => {
        return response
      })

      setCartUpdatedAt(Date.now())

      return response
    },
    {
      onError: options?.onCartItemAddError,
    }
  )

  /**
   * Update Cart Item
   */
  const cartItemUpdate = useMutation(
    ['cartItemUpdate'],
    async (variables: { cartId: string; itemId: string; quantity: number }) => {
      const params = {
        cartId: variables.cartId,
        version: cart?.version,
        lineItemId: variables.itemId,
        quantity: variables.quantity,
        locale,
      }

      const response = await cartItemUpdateService({
        graphqlClient,
        token: accessToken?.access_token ?? '',
        params,
      })

      queryClient.setQueryData(useCtCartKey, () => {
        return response
      })

      setCartUpdatedAt(Date.now())

      return response
    },
    {
      onError: options?.onCartItemUpdateError,
    }
  )

  /**
   * Delete Cart Item
   */
  const cartItemDelete = useMutation(
    ['cartItemDelete'],
    async (variables: { cartId: string; itemId: string }) => {
      const params = {
        cartId: variables.cartId,
        version: cart?.version,
        lineItemId: variables.itemId,
        locale,
      }

      const response = await cartItemDeleteService({
        graphqlClient,
        token: accessToken?.access_token ?? '',
        params,
      })

      queryClient.setQueryData(useCtCartKey, () => {
        return response
      })

      setCartUpdatedAt(Date.now())

      return response
    },
    {
      onError: options?.onCartItemDeleteError,
    }
  )

  const updateCartForShippingMethods = async (
    cartId: string | undefined,
    countryUpdated: string,
    shippingAddress: CheckoutStateShippingAddress
  ) => {
    if (!cartId) {
      return
    }

    await axios
      .post('/api/commercetools/create-weight-calculation', {
        currency: currency,
        countryUpdated: countryUpdated,
        shippingAddress,
      })
      .then((res) => res.data)
      .catch((error: AxiosError) => {
        console.log(error.message, error.response?.data)
        throw error
      })

    await fetchCart()

    setCartUpdatedAt(Date.now())
  }

  /**
   * Update Cart Shipping Address
   */
  const cartUpdateAddress = useMutation(
    ['updateAddress'],
    async (variables: {
      cartId: string
      shippingAddress?: AddressInput
      billingAddress?: AddressInput
    }) => {
      const params: Parameters<typeof cartAddressUpdateService>[0]['params'] = {
        ...variables,
        version: cart?.version,
        locale,
      }

      const response = await cartAddressUpdateService({
        graphqlClient,
        token: accessToken?.access_token ?? '',
        params,
      })

      queryClient.setQueryData(useCtCartKey, () => {
        return response
      })

      setCartUpdatedAt(Date.now())

      return response
    },
    {
      onError: options?.onCartAddressUpdateError,
    }
  )

  /**
   * Apply Idme Discount
   */
  const cartApplyIdMeDiscount = useMutation(
    ['addIdMeDiscount'],
    async (variables: { code: string }) => {
      const response = await axios.post(`/api/promotions/idme`, {
        idme_access_token: variables.code,
        currency: currency,
      })

      fetchCart()

      setCartUpdatedAt(Date.now())

      return response
    }
  )

  /**
   * Apply Discount Code
   */
  const cartApplyDiscountCode = useMutation(
    ['addDiscountCode'],
    async (variables: { code: string }) => {
      const params: { variables: UpdateMyCartMutationVariables } = {
        variables: {
          locale,
          version: cart?.version,
          id: cart?.id ?? '',
          actions: [
            {
              addDiscountCode: {
                code: variables.code,
              },
            },
          ],
        },
      }

      const response = await cartUpdateService({
        graphqlClient,
        token: accessToken?.access_token ?? '',
        params,
      })

      queryClient.setQueryData(useCtCartKey, () => {
        return response
      })

      setCartUpdatedAt(Date.now())

      return response
    }
  )

  /**
   * Remove Discount Code
   */
  const cartRemoveDiscountCode = useMutation(
    ['removeDiscountCode'],
    async (reference: Reference) => {
      const params: { variables: UpdateMyCartMutationVariables } = {
        variables: {
          locale,
          version: cart?.version,
          id: cart?.id ?? '',
          actions: [
            {
              removeDiscountCode: {
                discountCode: reference,
              },
            },
          ],
        },
      }

      const response = await cartUpdateService({
        graphqlClient,
        token: accessToken?.access_token ?? '',
        params,
      })

      queryClient.setQueryData(useCtCartKey, () => {
        return response
      })

      setCartUpdatedAt(Date.now())

      return response
    }
  )

  /**
   * Update Cart Shipping Address
   */
  const cartAddPayment = useMutation(
    ['addPayment'],
    async (variables: { paymentIds: { paymentId: string }[] }) => {
      const actions = []
      const ids = variables.paymentIds

      for (let i = 0; i < ids.length; i++) {
        actions.push({
          addPayment: {
            payment: {
              id: ids[i].paymentId,
              typeId: 'payment',
            },
          },
        })
      }

      const version = (await fetchCart()).data?.version

      const params: Parameters<typeof cartUpdateService>[0]['params'] = {
        variables: {
          locale,
          version: version,
          id: cart?.id ?? '',
          actions,
        },
      }

      const response = await cartUpdateService({
        graphqlClient,
        token: accessToken?.access_token ?? '',
        params,
      })

      queryClient.setQueryData(useCtCartKey, () => {
        return response
      })

      setCartUpdatedAt(Date.now())

      return response
    },
    {
      onError: options?.onCartAddPaymentError,
    }
  )

  /**
   * Update Cart
   */
  const cartUpdate = useMutation(
    ['update'],
    async (variables: {
      cartId: string
      actions: Array<MyCartUpdateAction> | MyCartUpdateAction
    }) => {
      const version = (await fetchCart()).data?.version

      const params: Parameters<typeof cartUpdateService>[0]['params'] = {
        variables: {
          locale,
          version: version,
          id: variables.cartId,
          actions: variables.actions,
        },
      }

      const response = await cartUpdateService({
        graphqlClient,
        token: accessToken?.access_token ?? '',
        params,
      })

      queryClient.setQueryData(useCtCartKey, () => {
        return response
      })

      setCartUpdatedAt(Date.now())

      return response
    },
    {
      onError: options?.onCartUpdateError,
      retry: 3,
    }
  )

  /**
   * Add Cart Item Mutation
   */
  const cartItemAddMutation = useCallback(
    async (params: {
      productId: string
      variantId?: number
      quantity?: number
      custom?: Maybe<CustomFieldsDraft>
    }) => {
      const id = cartId ? cartId : await cartCreate()

      await cartItemAdd.mutate(
        {
          cartId: id!,
          productId: params.productId,
          variantId: params.variantId,
          quantity: params.quantity,
          custom: params.custom,
        },
        {
          onSuccess: (response) => {
            gaTrackAddToCart({
              productId: params.productId,
              quantity: params.quantity ?? 1,
              nextCart: response ?? undefined,
              prevCart: cart,
            })
            optionsRef.current?.onCartItemAddSuccess?.(response)
          },
        }
      )
    },
    [cartId, cart, cartCreate, cartItemAdd]
  )

  /**
   * Update Cart Item Mutation
   */
  const cartItemUpdateMutation = useCallback(
    async (params: { itemId: string; quantity: number }) => {
      if (!cartId) {
        return
      }

      await cartItemUpdate.mutate(
        {
          cartId,
          itemId: params.itemId,
          quantity: params.quantity,
        },
        {
          onSuccess: (response) => {
            gaTrackUpdateCart({
              lineItemId: params.itemId,
              quantity: params.quantity,
              nextCart: response ?? undefined,
              prevCart: cart,
            })
          },
        }
      )
    },
    [cartId, cartItemUpdate]
  )

  /**
   * Delete Cart Item Mutation
   */
  const cartItemDeleteMutation = useCallback(
    async (params: { itemId: string }) => {
      if (!cartId) {
        return
      }
      await cartItemDelete.mutate(
        {
          cartId,
          itemId: params.itemId,
        },
        {
          onSuccess: (response) => {
            gaTrackRemoveFromCart({
              lineItemId: params.itemId,
              prevCart: cart,
            })
          },
        }
      )
    },
    [cartId, cartItemUpdate]
  )

  /**
   * Update Cart Shipping Address Mutation
   */
  const addressUpdateMutation = useCallback(
    async (params: {
      shippingAddress?: AddressInput
      billingAddress?: AddressInput
    }) => {
      if (!cartId) {
        return
      }

      return cartUpdateAddress.mutateAsync({
        cartId,
        shippingAddress: params.shippingAddress,
        billingAddress: params.billingAddress,
      })
    },
    [cartId]
  )

  /**
   * Add Cart Payment Mutation
   */
  const addPaymentMutation = useCallback(
    async (params: { paymentId: string }[]) => {
      if (!cartId) {
        return
      }

      return cartAddPayment.mutateAsync({ paymentIds: params })
    },
    [cartId]
  )

  /**
   * Update Cart Mutation
   */
  const updateMutation = useCallback(
    async (params: {
      actions: Array<MyCartUpdateAction> | MyCartUpdateAction
    }) => {
      if (!cartId) {
        return
      }

      let cartMutated = cart

      await cartUpdate.mutateAsync(
        {
          cartId,
          actions: params.actions,
        },
        {
          onSuccess: (response) => {
            cartMutated = response as Cart
          },
        }
      )

      return cartMutated
    },
    [cartId]
  )

  /**
   * addCartItem Facade
   */
  const addCartItem = useRef({
    mutate: cartItemAddMutation,
    isLoading: cartItemAdd.isLoading,
  })

  addCartItem.current.mutate = cartItemAddMutation
  addCartItem.current.isLoading = cartItemAdd.isLoading || creatingCart

  /**
   * updateCartItem Facade
   */
  const updateCartItem = useRef({
    mutate: cartItemUpdateMutation,
    isLoading: cartItemUpdate.isLoading,
  })

  updateCartItem.current.mutate = cartItemUpdateMutation
  updateCartItem.current.isLoading = cartItemUpdate.isLoading

  /**
   * deleteCartItem Facade
   */
  const deleteCartItem = useRef({
    mutate: cartItemDeleteMutation,
    isLoading: cartItemDelete.isLoading,
  })

  deleteCartItem.current.mutate = cartItemDeleteMutation
  deleteCartItem.current.isLoading = cartItemDelete.isLoading

  /**
   * addCartPayment Facade
   */
  const addCartPayment = useRef({
    mutate: addPaymentMutation,
    isLoading: cartAddPayment.isLoading,
  })

  addCartPayment.current.mutate = addPaymentMutation
  addCartPayment.current.isLoading = cartAddPayment.isLoading

  /**
   * addPayment Facade
   */
  const updateCartAddress = useRef({
    mutate: addressUpdateMutation,
    isLoading: cartUpdateAddress.isLoading,
  })

  updateCartAddress.current.mutate = addressUpdateMutation
  updateCartAddress.current.isLoading = cartUpdateAddress.isLoading

  /**
   * updateCart Facade
   */
  const updateCart = useRef({
    mutate: updateMutation,
    isLoading: cartUpdate.isLoading,
  })

  updateCart.current.mutate = updateMutation
  updateCart.current.isLoading = cartUpdate.isLoading

  /**
   * Fetch Cart on each update
   */
  useEffect(() => {
    if (!accessToken?.access_token) {
      return
    }
    fetchCart()
  }, [fetchCart, accessToken, updatedAt, currency])

  /**
   * Cart data
   */
  const cartData = useMemo(() => {
    return {
      ...cart,
      isFetched,
      isLoading: status === 'loading',
      isEmpty: !cart?.totalLineItemQuantity,
      quantity: cart?.totalLineItemQuantity ?? 0,
    }
  }, [cart, status, cartId])

  /**
   * Delete Cart Handler
   */
  const deleteCartHandler = useCallback(() => {
    queryClient.setQueryData(useCtCartKey, undefined)
    queryClient.removeQueries(useCtCartKey)
  }, [queryClient])

  /**
   * Public
   */

  return {
    addCartItem: addCartItem.current,
    updateCartItem: updateCartItem.current,
    deleteCartItem: deleteCartItem.current,
    updateCartAddress: updateCartAddress.current,
    addCartPayment: addCartPayment.current,
    updateCart: updateCart.current,
    applyCode: cartApplyDiscountCode,
    removeCode: cartRemoveDiscountCode,
    cart: cartData,
    cartApplyIdMeDiscount: cartApplyIdMeDiscount,
    updateCartForShippingMethods: updateCartForShippingMethods,
    deleteCart: deleteCartHandler,
    fetchCart: fetchCart,
  }
}
