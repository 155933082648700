import {
  Link as ChakraLink,
  LinkProps as ChakraLinkProps,
} from '@chakra-ui/react'
import { default as NextLink } from 'next/link'

export type LinkProps = ChakraLinkProps &
  Required<Pick<ChakraLinkProps, 'href'>>

// https://chakra-ui.com/docs/components/link/usage#usage-with-nextjs
export const Link = (props: LinkProps) => (
  <NextLink href={props.href} passHref>
    <ChakraLink {...props} />
  </NextLink>
)
