import {
  addressFragment,
  baseMoneyFragment,
  cartFragment,
  cartLineItemFragment,
  customerFragment,
  moneyFragment,
  taxedItemPriceFragment,
  taxedPriceFragment,
} from '../fragments'

export const getCartQuery = /* GraphQL */ `
  query getCart($id: String!, $locale: Locale = "en-US") {
    cart(id: $id) {
      ...cart
    }
  }
  ${cartFragment}
  ${customerFragment}
  ${cartLineItemFragment}
  ${moneyFragment}
  ${taxedPriceFragment}
  ${addressFragment}
  ${baseMoneyFragment}
  ${taxedItemPriceFragment}
`
