import textStyles from '../textStyles'

/**
 * Heading theming documentation:
 * https://chakra-ui.com/docs/components/heading/theming
 */

const headingStyles = {
  baseStyle: {
    /**
     * What we should be doing here is "textStyle: 'heading'", however:
     * https://github.com/chakra-ui/chakra-ui/issues/3884
     */
    ...textStyles['heading'],
  },
  sizes: {
    xs: {
      fontSize: 'sm',
      lineHeight: 'shorter',
    },
    sm: {
      fontSize: 'md',
      lineHeight: 'shorter',
    },
    md: {
      fontSize: 'xl',
      lineHeight: 'shorter',
    },
    lg: {
      fontSize: '2xl',
      lineHeight: 'shorter',
    },
    xl: {
      fontSize: '3xl',
      lineHeight: 'shorter',
    },
    '2xl': {
      fontSize: '4xl',
      lineHeight: 'shorter',
    },
    '3xl': {
      fontSize: '5xl',
      lineHeight: 'shorter',
    },
    '4xl': {
      fontSize: '6xl',
      lineHeight: 'shorter',
    },
  },
  variants: {
    serif: {
      /**
       * What we should be doing here is "textStyle: 'heading'", however:
       * https://github.com/chakra-ui/chakra-ui/issues/3884
       */
      ...textStyles['heading-serif'],
    },
  },
}

export default headingStyles
