import { StaticAppParams, staticCoreApp } from '@oriuminc/base'
import { contentfulPageFetchService, getContentfulPageKey } from '../hooks'
import { HOME_PAGE } from '../constants'
import { prefetchCommonComponents } from '../utils'

export const staticContenfulPage: ({ context }: StaticAppParams) => Promise<{
  getServerSidePropsValue: () => {
    revalidate: number
    notFound: boolean
    props: { dehydratedState: import('@tanstack/react-query').DehydratedState }
  }
}> = async ({ context }: StaticAppParams) => {
  const staticApp = staticCoreApp({ context })
  const { queryClient, slug, locale, getStaticProps } = staticApp

  const _slug = `${slug || HOME_PAGE}`
  const _locale = locale || ''
  const page = await contentfulPageFetchService({
    slug: _slug,
    locale: _locale,
  })
  const notFound = Boolean(!page)

  await queryClient.prefetchQuery(
    getContentfulPageKey(_slug, _locale),
    () => page
  )

  await prefetchCommonComponents({ queryClient, locale })

  return {
    getServerSidePropsValue: () => {
      const props = getStaticProps()
      return {
        props,
        notFound,
        revalidate: 60,
      }
    },
  }
}
