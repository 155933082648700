import { useQuery } from '@tanstack/react-query'
import { useCommercetools } from '../../components'
import { customerFetchService } from '../../services'
import { useAuth } from '../useAuth'

export const useUserKey = 'useUserKey'

export const getUseUserKey = (customerToken: string) => [
  useUserKey,
  customerToken,
]

export const useUser = () => {
  const auth = useAuth()
  const { graphqlClient, customerToken } = useCommercetools()
  const query = useQuery(
    getUseUserKey(customerToken),
    () => {
      return customerFetchService({
        graphqlClient,
        token: customerToken,
      })
    },
    {
      retry: false,
      enabled: Boolean(customerToken),
    }
  )

  return {
    ...auth,
    customer: query.data ?? null,
    isLoading: query.isLoading,
  }
}
