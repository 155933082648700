import { AFTERPAY_PAYMENT_SUCCESS_CODE } from '@modules/app'
import { AddressFragment, Maybe } from '@modules/commercetools'
import { AfterpayCheckoutProps, AfterpayConfig } from 'types'

export const AFTERPAY_ERRORS = {
  INVALID_COUNTRY: 'AFTERPAY_INVALID_COUNTRY',
  INVALID_CURRENCY: 'AFTERPAY_INVALID_CURRENCY',
  INVALID_AMOUNT: 'AFTERPAY_INVALID_AMOUNT',
  INVALID_ITEM_IN_CART: 'AFTERPAY_INVALID_ITEM_IN_CART',
  BAD_REQUEST: 'BAD_REQUEST',
}

// Afterpay payment documentation
interface AfterpayPayment {
  status: string
}

/* The required fields vary by country; this is the set of fields
that is required in every country */
const BILLING_REQUIRED_FIELDS = [
  'city',
  'firstName',
  'lastName',
  'state',
  'streetName',
]

export const afterpayEligibility = (
  config: AfterpayConfig,
  orderData: AfterpayCheckoutProps,
  supportedCountries: string[],
  isSubscriptionInOrder?: boolean
) => {
  const { userDetails, billing, amount, shippingAmount, taxAmount } =
    orderData || {}
  const orderAmount = orderData?.amount?.amount || 0
  const configuredCurrency = config?.minimumAmount?.currency
  const lowerLimit = config?.minimumAmount?.amount
  const upperLimit = config?.maximumAmount?.amount

  let status: number = 200
  let message: string = 'The order is eligible'
  let errorCode: string = ''

  const billingCountryInvalid =
    billing?.country && !supportedCountries.includes(billing.country)
  const countryInvalid =
    !userDetails?.country || !supportedCountries.includes(userDetails.country)
  const countryNotSupported = countryInvalid || billingCountryInvalid
  const currencyNotSupported =
    configuredCurrency !== amount?.currency ||
    (taxAmount?.currency && configuredCurrency !== taxAmount.currency) ||
    configuredCurrency !== userDetails?.currency
  const orderAmountNotSupported =
    orderAmount < parseFloat(lowerLimit) || orderAmount > parseFloat(upperLimit)

  if (!userDetails) {
    status = 400
    message = 'No user data present for this order'
    errorCode = AFTERPAY_ERRORS.BAD_REQUEST
  } else if (isSubscriptionInOrder) {
    status = 200
    message = `Subscriptions are not eligible for purchase with Afterpay`
    errorCode = AFTERPAY_ERRORS.INVALID_ITEM_IN_CART
  } else if (countryNotSupported) {
    const supportedCountriesString = supportedCountries.join(' ')
    status = 200
    message = `Countries outside of this list of countries is not supported: ${supportedCountriesString}`
    errorCode = AFTERPAY_ERRORS.INVALID_COUNTRY
  } else if (currencyNotSupported) {
    status = 200
    message =
      'The currency used in this order is not supported. Orders must be in ' +
      configuredCurrency
    errorCode = AFTERPAY_ERRORS.INVALID_CURRENCY
  } else if (orderAmountNotSupported) {
    status = 200
    message = `The order amount is outside of the range ${lowerLimit} to ${upperLimit} ${configuredCurrency}`
    errorCode = AFTERPAY_ERRORS.INVALID_AMOUNT
  }
  return {
    status,
    message,
    errorCode,
  }
}

export const billingDetailsSet = (
  billingAddress:
    | Maybe<{ __typename?: 'Address' } & AddressFragment>
    | undefined
) => {
  if (!billingAddress) {
    return false
  }

  let billingDetailsSet = true

  for (let i = 0; i < BILLING_REQUIRED_FIELDS.length; i++) {
    const field = BILLING_REQUIRED_FIELDS[i]
    const propertyExists =
      billingAddress?.hasOwnProperty(field) &&
      (billingAddress as { [key: string]: string })[field]
    if (!propertyExists) {
      billingDetailsSet = false
      break
    }
  }

  return billingDetailsSet
}

export const isProcessorSuccess = (processorResponseCode: string) =>
  AFTERPAY_PAYMENT_SUCCESS_CODE === processorResponseCode

export const handleAfterpayProcessorResponse = (payment: AfterpayPayment) => {
  if (!isProcessorSuccess(payment?.status || '')) {
    throw new Error('Afterpay payment declined')
  }
}
