import { useCart } from '@modules/commercetools'
import { AlpCurrency } from '@modules/spoonflower/utils/graphql/generated/graphql'
import { useToast } from '@modules/ui'
import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import { useIntl } from 'react-intl'

enum MergeCartsStatus {
  CLAIMED = 'CLAIMED',
  FAILED = 'FAILED',
  MERGED = 'MERGED',
  UNTOUCHED = 'UNTOUCHED',
}

interface MergeCartsResponse {
  mergeStatus: MergeCartsStatus
}

interface MergeCartsVariables {
  guestCartID: string
  userCustomerID: string
  userCurrency: AlpCurrency
}

const useMergeCartsKey = ['useMergeCarts']

function useMergeCarts() {
  const { fetchCart } = useCart()
  const intl = useIntl()
  const toast = useToast()

  return useMutation<MergeCartsStatus, unknown, MergeCartsVariables>(
    useMergeCartsKey,
    async (variables) => {
      const options = {
        withCredentials: true,
      }
      const url = '/api/commercetools/merge-carts'
      const mergeStatus = await axios
        .post<MergeCartsResponse>(url, variables, options)
        .then(({ data }) => data.mergeStatus)
        .catch((error) => {
          toast({
            duration: null,
            status: 'error',
            description: intl.formatMessage({
              id: 'cart.merge.failure',
            }),
          })

          return MergeCartsStatus.FAILED
        })

      if (mergeStatus !== MergeCartsStatus.UNTOUCHED) {
        await fetchCart()
      }

      return mergeStatus
    }
  )
}

export { MergeCartsStatus, useMergeCarts as default, useMergeCartsKey }
