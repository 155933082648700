import { useQuery } from '@tanstack/react-query'
import { useComposable } from '../../components'

export const getSiteConfigKey = (locale: string) => ['siteConfig', locale ?? '']

export const useSiteConfig = () => {
  const { getSiteConfig, locale, appKey } = useComposable()
  const query = useQuery(
    getSiteConfigKey(locale),
    () => getSiteConfig?.({ locale, key: appKey }),
    {
      keepPreviousData: true,
    }
  )

  return {
    sites: query.data
      ? [query.data, ...(query.data?.siblingSites || [])]
      : undefined,
    currentSite: query.data,
    isNoMatch: query.data === null,
    isLoading: query.data === undefined,
  }
}
