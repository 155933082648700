export const customerFragment = /* GraphQL */ `
  fragment customer on Customer {
    id
    email
    firstName
    lastName
    addresses {
      id
    }
    version
  }
`
