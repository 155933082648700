export const componentTextCardFragment = /* GraphQL */ `
  fragment componentTextCard on ComponentTextCard {
    sys {
      id
    }
    title(locale: $locale)
    image {
      ...contentfulAsset
    }
    content(locale: $locale) {
      json
    }
    ctaLabel(locale: $locale)
    ctaHref(locale: $locale)
    theme(locale: $locale)
    textAlign(locale: $locale)
  }
`
