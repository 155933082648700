export const componentGridFragment = /* GraphQL */ `
  fragment componentGrid on ComponentGrid {
    sys {
      id
    }
    columns
    gridGap
    containerSize(locale: $locale)
    containerMarginTop(locale: $locale)
    containerMarginBottom(locale: $locale)
    contentCollection(limit: 8) {
      items {
        __typename
        ... on ComponentArticleCard {
          ...componentArticleCard
        }
        ... on ComponentCoverCard {
          ...componentCoverCard
        }
        ... on ComponentTextCard {
          ...componentTextCard
        }
      }
    }
  }
`
