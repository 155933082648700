import { cssVar } from '@chakra-ui/theme-tools'

const $arrowBg = cssVar('popper-arrow-bg')

const tooltipStyles = {
  baseStyle: {
    padding: 2,
    textAlign: 'center',
    borderRadius: 'md',
  },
  variants: {
    dark: {
      backgroundColor: 'gray.900',
      color: 'white',
      [$arrowBg.variable]: 'colors.gray.900',
    },
    light: {},
  },
}

export default tooltipStyles
