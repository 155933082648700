const alertStyles = {
  variants: {
    'cart-action': {
      container: {
        backgroundColor: 'gray-opacity.700',
        color: 'warm-white',
        paddingX: '6',
        paddingY: '3',
      },
      description: {
        fontWeight: 'semibold',
      },
      icon: {
        /**
         * "display: 'none'" will not work here, so this hack will have to do.
         */
        margin: '0',
        padding: '0',
        width: '0',
      },
    },
  },
  defaultProps: {
    variant: 'subtle',
  },
}

export default alertStyles
