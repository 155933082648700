const radioStyles = {
  // Styles for the base style
  baseStyle: {
    control: {
      borderColor: 'black',

      _checked: {
        background: 'teal.600',
        borderColor: 'teal.600',

        _hover: {
          background: 'teal.600',
          borderColor: 'teal.600',
        },
      },

      _hover: {},
    },
  },
}

export default radioStyles
