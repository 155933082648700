import { getUserAnalytics } from '@spoonflower/user-analytics'
import { PAGE_TYPE_CT_CART } from '@modules/app'
import { useComposable } from '@modules/oriuminc/base'
import { PATHS } from 'constants/paths'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import useUserStats from '../../../../app/hooks/useUserStats'
import useWhoAmI from '../../../../app/hooks/useWhoAmI'
import { useCart, useProductBySlug } from '../../hooks'
import {
  gaTrackBeginCheckout,
  gaTrackClearEcommerce,
  gaTrackPageType,
  gaTrackPageView,
  gaTrackUserInformation,
  gaTrackViewCart,
  gaTrackViewItem,
} from '../../integrations'
import { CartFragment } from '../../types'
import { getCartDiscounts } from '../../utils'

export const Analytics = () => {
  const router = useRouter()
  const { path, cartDrawer } = useComposable()
  const { cart } = useCart()
  const { lineItemDiscounts } = getCartDiscounts(cart as CartFragment)
  const { data: user, isFetched } = useWhoAmI()
  const { id: userId } = user ?? {}
  const { product } = useProductBySlug(`${router.query?.slug}`)
  const { stats, statsFetched } = useUserStats(
    user?.id as number,
    (user?.preferences?.locale as string) || 'en'
  )

  // Prevent multiple event firing for View Cart and Begin Checkout
  const [hasViewedCart, setHasViewedCart] = useState(false)
  const [hasBegunCheckout, setHasBegunCheckout] = useState(false)
  // Couldn't user react query for this because of the way the data is fetched
  const [gaStatsFired, setGaStatsFired] = useState(false)

  useEffect(() => {
    gaTrackPageType(PAGE_TYPE_CT_CART)
  }, [])

  useEffect(() => {
    // When navigating back and forth through checkout steps state does not reset and it needs to be reset to fire view_cart and begin_checkout events again
    setHasViewedCart(false)
  }, [router.pathname])

  useEffect(() => {
    getUserAnalytics({
      url: '/api/spoonflower/get-user-stats',
    }).then((data) => {
      if (data) {
        gaTrackPageView(
          PAGE_TYPE_CT_CART,
          data.data_layer,
          data.cart?.cart_id,
          data.cart?.guest_cart_id
        )
      }
    })
  }, [router.asPath])

  useEffect(() => {
    const inCartPage = router.asPath == PATHS.CART
    if (
      cart?.id &&
      (inCartPage || cartDrawer.isOpen) &&
      !hasViewedCart &&
      isFetched &&
      gaStatsFired
    ) {
      gaTrackViewCart({ cart, discounts: lineItemDiscounts })
      setHasViewedCart(true)
      setHasBegunCheckout(false)
    }
  }, [
    router,
    cart,
    path,
    cartDrawer.isOpen,
    hasViewedCart,
    userId,
    isFetched,
    gaStatsFired,
  ])

  // Track Checkout Begin
  useEffect(() => {
    const isCheckoutPage = router.asPath === path.getCheckout()
    if (isCheckoutPage && cart?.id && !hasBegunCheckout) {
      gaTrackClearEcommerce()
      gaTrackBeginCheckout({ cart, discounts: lineItemDiscounts })
      setHasBegunCheckout(true)
    }
  }, [router, cart, path, hasBegunCheckout])

  // Track View Item
  useEffect(() => {
    const isProductPage = router.asPath.startsWith(path.getPDP({ slug: '' }))
    if (isProductPage && product) {
      gaTrackViewItem({ product })
    }
  }, [router, path, product])

  // Track User Information
  useEffect(() => {
    if (stats) {
      gaTrackUserInformation(PAGE_TYPE_CT_CART, stats?.data?.data_layer)
    }
    if (statsFetched) {
      setGaStatsFired(true)
    }
  }, [statsFetched])

  return null
}
