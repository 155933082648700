const linkStyles = {
  baseStyle: {
    color: 'link-text',
    fontWeight: 'semibold',
    textDecoration: 'underline',
    textUnderlineOffset: '3px',
    textDecorationThickness: '1px',
    letterSpacing: 0.2,

    _hover: {
      color: 'link-text-hover',
    },
  },
}

export default linkStyles
