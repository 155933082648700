import { default as chakraTheme } from '@chakra-ui/theme'

export const palette = {
  // https://chakra-ui.com/docs/styled-system/theme#colors
  ...chakraTheme.colors,
  primary: {
    // teal
    50: '#EFF6F6',
    100: '#DAEAEA',
    200: '#9EC6C6',
    300: '#7AAAAC',
    400: '#568E92',
    500: '#337179',
    600: '#1F6169',
    700: '#0D5257',
    800: '#074247',
    900: '#032C2F',
  },
  secondary: {
    // yellow
    50: '#FCF7E8',
    100: '#F8EFCD',
    200: '#F5E1A4',
    300: '#F3D792',
    400: '#F1CD80',
    500: '#EFC06E',
    600: '#CBA052',
    700: '#b58500',
    800: '#8f6a00',
    900: '#684d00',
  },
  tertiary: {
    // earth
    50: '#FBF1E9',
    100: '#F3CFB3',
    200: '#EBB38C',
    300: '#E59E6D',
    400: '#D0835B',
    500: '#B86748',
    600: '#A0593E',
    700: '#7C442F',
    800: '#713F2A',
    900: '#5B3122',
  },
  success: {
    // green
    50: '#F2FCF9',
    100: '#A7E6D7',
    200: '#6ECEB2',
    300: '#27B093',
    400: '#219884',
    500: '#1C8172',
    600: '#1A7667',
    700: '#176353',
    800: '#124A3B',
    900: '#0E3C32',
  },
  danger: {
    // red
    50: '#FBE9EA',
    100: '#EBC1C2',
    200: '#DC989B',
    300: '#CD6F74',
    400: '#BE464D',
    500: '#AF1D26',
    600: '#921821',
    700: '#73131A',
    800: '#540E13',
    900: '#35090C',
  },
  warning: {
    // yellow
    100: '#F8EFCD',
    200: '#F5E1A4',
    300: '#F3D792',
    400: '#F1CD80',
    500: '#EFC06E',
    600: '#CBA052',
    700: '#b58500',
    800: '#8f6a00',
    900: '#684d00',
  },
  info: {
    100: '#DCF4FE',
    200: '#B9E6FE',
    300: '#96D4FE',
    400: '#7BC2FD',
    500: '#50A6FC',
    600: '#3A81D8',
    700: '#2860B5',
    800: '#194392',
    900: '#0F2F78',
  },
  shading: {
    100: '#F4F4F4',
    200: '#EAEAEA',
    300: '#C0C0C0',
    400: '#828282',
    500: '#404040',
    600: '#303030',
    700: '#222222',
    800: '#1B1B1B',
    900: '#111111',
  },
  // #endregion

  // #region Spoonflower theme
  gray: {
    50: '#F1F3F3',
    100: '#D3D4D7',
    200: '#B3B7BA',
    300: '#939A9D',
    400: '#737D80',
    500: '#536063',
    600: '#424C4D',
    700: '#303738',
    800: '#1E2223',
    900: '#0C0D0E',
  },
  /**
   * You'll notice that this is this exactly the same as "gray".
   * This is because we want to use the "gray" color scheme in buttons
   * without Chakra's alternate styling for it.
   *
   * Why "granite"? It's the color name for the "500" shade.
   * https://www.color-name.com/hex/61696b
   */
  granite: {
    50: '#F1F3F3',
    100: '#D3D4D7',
    200: '#B3B7BA',
    300: '#939A9D',
    400: '#737D80',
    500: '#536063',
    600: '#424C4D',
    700: '#303738',
    800: '#1E2223',
    900: '#0C0D0E',
  },
  teal: {
    // use primary
    50: '#EFF6F6',
    100: '#DAEAEA',
    200: '#9EC6C6',
    300: '#7AAAAC',
    400: '#568E92',
    500: '#337179',
    600: '#1F6169',
    700: '#0D5257',
    800: '#074247',
    900: '#032C2F',
  },
  yellow: {
    50: '#FCF7E8', // use secondary
    100: '#F8EFCD', // use warning
    200: '#F5E1A4',
    300: '#F3D792',
    400: '#F1CD80',
    500: '#EFC06E',
    600: '#CBA052',
    700: '#B58500',
    800: '#8F6A00',
    900: '#684D00',
  },
  earth: {
    // use tertiary
    50: '#FBF1E9',
    100: '#F3CFB3',
    200: '#EBB38C',
    300: '#E59E6D',
    400: '#D0835B',
    500: '#B86748',
    600: '#A0593E',
    700: '#7C442F',
    800: '#713F2A',
    900: '#5B3122',
  },
  green: {
    // use success
    50: '#F2FCF9',
    100: '#A7E6D7',
    200: '#6ECEB2',
    300: '#27B093',
    400: '#219884',
    500: '#1C8172',
    600: '#1A7667',
    700: '#176353',
    800: '#124A3B',
    900: '#0E3C32',
  },
  red: {
    // use danger
    50: '#FBE9EA',
    100: '#EBC1C2',
    200: '#DC989B',
    300: '#CD6F74',
    400: '#BE464D',
    500: '#AF1D26',
    600: '#921821',
    700: '#73131A',
    800: '#540E13',
    900: '#35090C',
  },
  black: '#000000',
  white: '#FFFFFF',
  'warm-white': '#FEFEFE',
  'gray-opacity': {
    50: 'rgba(0, 0, 0, 0.04)',
    100: 'rgba(0, 0, 0, 0.06)',
    200: 'rgba(0, 0, 0, 0.08)',
    300: 'rgba(0, 0, 0, 0.16)',
    400: 'rgba(0, 0, 0, 0.24)',
    500: 'rgba(0, 0, 0, 0.36)',
    600: 'rgba(0, 0, 0, 0.48)',
    700: 'rgba(0, 0, 0, 0.64)',
    800: 'rgba(0, 0, 0, 0.80)',
    900: 'rgba(0, 0, 0, 0.92)',
  },
  'white-opacity': {
    50: 'rgba(255, 255, 255, 0.04)',
    100: 'rgba(255, 255, 255, 0.06)',
    200: 'rgba(255, 255, 255, 0.08)',
    300: 'rgba(255, 255, 255, 0.16)',
    400: 'rgba(255, 255, 255, 0.24)',
    500: 'rgba(255, 255, 255, 0.36)',
    600: 'rgba(255, 255, 255, 0.48)',
    700: 'rgba(255, 255, 255, 0.64)',
    800: 'rgba(255, 255, 255, 0.80)',
    900: 'rgba(255, 255, 255, 0.92)',
  },
  'border-color': '#EFEFEF',
}

// Color Tokens - Global Status
const colorTokens = {
  // #region Orium theme
  // info
  'info-light': palette.info['100'],
  'info-med': palette.info['500'],
  'info-dark': palette.info['900'],
  // success
  'success-light': palette.success['100'],
  'success-med': palette.success['500'],
  'success-dark': palette.success['900'],
  // warning
  'warning-light': palette.warning['100'],
  'warning-med': palette.warning['500'],
  'warning-dark': palette.warning['700'],
  // danger
  'danger-light': palette.danger['100'],
  'danger-med': palette.danger['500'],
  'danger-dark': palette.danger['900'],
  // #endregion
}

export const paletteTokens = {
  colors: {
    // #region Orium theme
    light: {
      background: palette['warm-white'],
      text: palette.black, //Body foreground color
      'text-muted': palette.shading['400'], // Body foreground color (muted) for alternative styling
      // TODO update once we have CMS controlled theme
      primary: palette.primary['600'], // Primary color for links, buttons, etc.
      secondary: palette.secondary['700'], //A secondary brand color for alternative styling
      highlight: palette.secondary['500'], //A highlight color for emphasizing UI
      muted: palette.tertiary['500'],
      accent: palette.tertiary['700'], //A contrast color for emphasizing UI
    },
    dark: {
      background: palette.black,
      text: palette.shading['100'], //Body foreground color
      'text-muted': palette.shading['300'], // Body foreground color (muted) for alternative styling
      // TODO update once we have CMS controlled theme
      primary: palette.primary['600'], // Primary color for links, buttons, etc
      secondary: palette.primary['400'], //A secondary brand color for alternative styling
      highlight: palette.secondary['500'], //A highlight color for emphasizing UI
      muted: palette.tertiary['500'],
      accent: palette.tertiary['700'], //A contrast color for emphasizing UI
    },
    // #endregion

    // #region Spoonflower theme
    link: {
      teal: {
        base: palette.teal[500],
        hover: palette.teal[700],
      },
    },
    text: {
      disabled: palette.gray[400],
      primary: {
        base: palette.gray[900],
        hover: palette.black,
      },
      secondary: {
        base: palette.gray[700],
        hover: palette.gray[900],
      },
      tertiary: {
        base: palette.gray[500],
        hover: palette.gray[700],
      },
    },
    // #endregion
  },
}
export type Colors = typeof palette & typeof paletteTokens & typeof colorTokens

const colors = { ...palette, ...paletteTokens, ...colorTokens }

export default colors
