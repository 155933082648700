import { LineItem } from '@commercetools/platform-sdk'
import { SPOONFLOWER_BASE_URL } from '@modules/app/constants'
import {
  CartFragment,
  Maybe,
  RawCustomField,
  ShippingOption,
} from '@modules/commercetools'
import { ProductKey } from '@modules/spoonflower/models/productKey'
import { AfterpayConfigResponse } from 'types'
import { AFTERPAY_ERRORS } from 'utils/afterpay'

export interface CustomAttributeHashTable {
  [key: string]: string
}

export const buildHashTable = (
  customFieldsRaw:
    | Maybe<
        ({
          __typename?: 'RawCustomField' | undefined
        } & Pick<RawCustomField, 'value' | 'name'>)[]
      >
    | undefined
) => {
  const customDataHashTable: CustomAttributeHashTable = {}

  for (let j = 0; j < (customFieldsRaw?.length || 0); j++) {
    const name: string = customFieldsRaw?.[j]?.name || ''
    if (name) {
      customDataHashTable[name] = customFieldsRaw?.[j]?.value
    }
  }

  return customDataHashTable
}

export const cartPageShowAfterpay = (
  isCartPage: boolean,
  afterpayConfig: (AfterpayConfigResponse & {}) | undefined
) => {
  const { INVALID_COUNTRY, INVALID_CURRENCY } = AFTERPAY_ERRORS
  return (
    isCartPage &&
    afterpayConfig?.errorCode !== INVALID_COUNTRY &&
    afterpayConfig?.errorCode !== INVALID_CURRENCY
  )
}

export const showAfterpay = (
  isCartPage: boolean,
  afterpayConfig: (AfterpayConfigResponse & {}) | undefined,
  showWhenUnavailable: boolean,
  currency: string,
  cartPageShowAfterpay: boolean
) => {
  const { config, errorCode } = afterpayConfig || {}
  const checkoutShowAfterpay: boolean = !isCartPage && !errorCode
  const isConfigUpdated: boolean = !!(
    config && currency === config.minimumAmount.currency
  )
  const showAfterpay =
    isConfigUpdated &&
    (cartPageShowAfterpay ||
      showWhenUnavailable ||
      (checkoutShowAfterpay && showWhenUnavailable))

  return showAfterpay
}

export const getLegacyPDP = (
  legacyPath: string,
  designID: string,
  locale: string
) =>
  !!legacyPath
    ? `${SPOONFLOWER_BASE_URL}/${locale}/${legacyPath}`.replace(
        '$DESIGNID',
        designID
      )
    : ''

export const findShippingMethod = (
  shippingOptions: ShippingOption[],
  key: string
) => {
  const shippingMethod = shippingOptions.find(
    (shippingOption) => shippingOption.key === key
  )
  return shippingMethod ? shippingMethod.id : undefined
}

// helpers to figure out if a subscirption type product is in the cart
export const getProductTypesInCart = (
  lineItems: Partial<CartFragment['lineItems']>
) => {
  const productKeysInCart = lineItems?.map((lineItem) => {
    return lineItem?.productKey
  })

  const uniqueProductKeysInCart = [...new Set(productKeysInCart)]
  return uniqueProductKeysInCart
}

export const isSubscriptionTypeProductInCart = (
  lineItems: Partial<CartFragment['lineItems']>
) => {
  const uniqueProductKeysInCart = getProductTypesInCart(lineItems)
  return uniqueProductKeysInCart.includes(ProductKey.Subscriptions)
}

export const isSubscriptionTypeProductTheOnlyProductTypeInCart = (
  lineItems: Partial<CartFragment['lineItems']>
) => {
  const uniqueProductKeysInCart = getProductTypesInCart(lineItems)
  return (
    uniqueProductKeysInCart.length === 1 &&
    uniqueProductKeysInCart.includes(ProductKey.Subscriptions)
  )
}

export const findSubscriptionLineItem = (lineItems: LineItem[]) => {
  return lineItems?.find(
    (lineItem) => lineItem?.productKey === ProductKey.Subscriptions
  )
}
