import {
  CtPageableService,
  CtPagedQueryResult,
  CtProductProjection,
  CtServiceDeps,
} from '../../types'
import { getAuthHeader } from '../../utils'
import { GraphqlClientRequired } from '../../utils/GraphqlClientRequired'
import {
  GetProductsQuery,
  GetProductsQueryVariables,
} from '../../types/commercetools-schema'
import { getProductsQuery } from '../../utils/queries'
import { HttpClientRequired } from '../../utils/HttpClientRequired'

export const productsBySkusFetchService = async ({
  httpClient,
  params,
  token,
}: CtServiceDeps<{
  skus: string[]
}> &
  CtPageableService) => {
  if (!httpClient) {
    throw new HttpClientRequired()
  }
  const { skus, limit = 20, offset = 0 } = params
  const _skus = skus.map((sku) => `"${sku}"`).join(',')
  const filter = _skus ? `variants.sku:${_skus}` : undefined
  const res = await httpClient.get<CtPagedQueryResult<CtProductProjection>>(
    '/product-projections/search',
    {
      params: { filter, limit, offset },
      headers: {
        authorization: getAuthHeader(token),
      },
    }
  )

  return res.data
}

export const productsFetchService = async ({
  graphqlClient,
  token,
  params,
}: CtServiceDeps<Omit<GetProductsQueryVariables, 'text'>>) => {
  if (!graphqlClient) {
    throw new GraphqlClientRequired()
  }

  if (!params)
    throw new Error('params must be passed to productListFetchServiceGraphQL')

  const res = await graphqlClient
    .query<GetProductsQuery, GetProductsQueryVariables>(
      getProductsQuery,
      { ...params },
      {
        fetchOptions: {
          headers: {
            authorization: getAuthHeader(token),
          },
        },
      }
    )
    .toPromise()

  if (res.error) {
    throw new Error(res.error.graphQLErrors[0]?.message || res.error.message)
  }

  return res.data?.productProjectionSearch
}
