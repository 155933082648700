import { IntlConfig } from '../../types'

interface GetActiveLocaleCurrency {
  activeLocale: string
  intlConfig: IntlConfig[]
}

export const getActiveLocaleCurrency = ({
  activeLocale,
  intlConfig,
}: GetActiveLocaleCurrency) => {
  return intlConfig.find((element) => element.code === activeLocale)?.currency
}
