import { DefaultSeo as NextDefaultSeo } from 'next-seo'
import * as React from 'react'
import { useSiteConfig } from '../../hooks'
import { useComposable } from '../ComposableProvider'
import { parseUrlTemplate } from '../../utils'

export const DefaultSeo = () => {
  const { locale } = useComposable()
  const { currentSite } = useSiteConfig()

  if (!currentSite) return null

  return (
    <NextDefaultSeo
      defaultTitle={currentSite.name ?? undefined}
      titleTemplate={currentSite.name ? `%s - ${currentSite.name}` : undefined}
      openGraph={{
        type: 'website',
        locale,
        url: parseUrlTemplate(currentSite?.url) ?? undefined,
        site_name: currentSite.name ?? undefined,
      }}
      additionalLinkTags={
        currentSite.shortcutIcon?.url
          ? [
              {
                rel: 'icon',
                href: currentSite.shortcutIcon.url,
              },
            ]
          : undefined
      }
    />
  )
}
