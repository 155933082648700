import { Button } from '@chakra-ui/react'
import { PATHS } from 'constants/paths'
import { useRouter } from 'next/router'
import { useIntl } from 'react-intl'

interface CheckoutStepLinksProps {
  title: string
  isAllowed: boolean
  active: boolean | (() => boolean)
  onClick?: () => void
  cartLinkBaseColor?: string
  isPreviouslyCompleted?: boolean
  display?: string
}

export const CheckoutStepLink = ({
  isAllowed,
  active,
  title,
  onClick,
  cartLinkBaseColor,
  // isPreviouslyCompleted,
  display,
}: CheckoutStepLinksProps) => {
  const inactiveColor = cartLinkBaseColor ? cartLinkBaseColor : 'gray.600'
  const router = useRouter()
  const intl = useIntl()
  const isCartPage = router.pathname === PATHS.CART

  const checkIsCartPage = () => {
    if (isCartPage && active) return 'teal.700'

    return checkIsActive()
  }

  const checkIsActive = () => (active ? 'teal.700' : 'transparent')

  const cartTitle = intl.formatMessage({ id: 'navLink.title.cart' })

  return (
    <Button
      as={title === cartTitle ? 'button' : 'p'}
      display={display}
      borderBottomColor={checkIsCartPage()}
      paddingTop={{ base: 'xxs' }}
      paddingBottom={{ base: 'xs', md: 0 }}
      borderBottomWidth="2px"
      color={active ? 'teal.700' : inactiveColor}
      // color={active || isPreviouslyCompleted ? 'teal.700' : inactiveColor}
      disabled={!isAllowed}
      onClick={() => title === cartTitle && onClick !== undefined && onClick()}
      // onClick={() => isAllowed && onClick !== undefined && onClick()}
      variant="link"
      fontSize="md"
      lineHeight="5"
      cursor={title === cartTitle ? 'pointer' : 'default'}
      _disabled={{
        color: 'gray.500',
        // cursor: 'not-allowed',
      }}
      _hover={{
        backgroundColor: 'transparent',
      }}
    >
      {title}
    </Button>
  )
}
