export const getCustomerDataQuery = /* GraphQL */ `
  query getCustomerData {
    me {
      customer {
        id
        key
        title
        email
        firstName
        lastName
        locale
        password
        version
        defaultBillingAddressId
        defaultShippingAddressId

        addresses {
          id
          key
          title
          streetName
          streetNumber
          additionalStreetInfo
          additionalAddressInfo
          postalCode
          city
          region
          state
          country
          building
          apartment
          pOBox
          phone
          mobile
          email
          title
          firstName
          lastName
          salutation
          fax
        }
        customerGroup {
          id
        }
      }
    }
  }
`
